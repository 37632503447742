import React, { useEffect, useRef, useState } from 'react';
import styles from './Profile.module.scss';
import { post } from '../../api';
import secureLocalStorage from 'react-secure-storage';
import { useHistory } from "react-router-dom";
import SignaturePad from 'signature_pad';
import { toast } from 'react-toastify';


const Profile = () => {
    const canvasRef = useRef(null);
    const [user, setUser] = useState([]);
    const signaturePadRef = useRef(null);
    const displayedUser = JSON.parse(secureLocalStorage.getItem('user'));
    const isOnline = navigator.onLine;
    const history = useHistory();

    useEffect(() => {
        const fetchData = async () => {
            const resp = await post(`/user`, { filterlist: `WHERE id = ${displayedUser.id} AND status = 1` });
            const users = await resp.json()
            setUser(users);
        }
        fetchData();
    }, [])

    useEffect(() => {
        const canvas = canvasRef.current;
        const signaturePad = new SignaturePad(canvas);

        signaturePadRef.current = signaturePad;
        signaturePad.minWidth = 1;
        signaturePad.maxWidth = 3;
    
        return () => {
        signaturePad.clear();
        };
      }, []);
    
    const handleClear = () => {
        const signaturePad = new SignaturePad(canvasRef.current);
        signaturePad.clear();
    };

    const saveSignature = async () => {
        if (signaturePadRef.current) {
          const signatureData = signaturePadRef.current.toDataURL();
      
          if (signaturePadRef.current.isEmpty()) {
            toast.error("No inspector signature has been drawn");
          } else {
            toast.dismiss();
      
            if (isOnline) {
              const res = await post('/user_update_signature', { inspectSignature: signatureData.split(',')[1], userid: displayedUser.id });
      
              if (res.ok) {
                toast.success("Inspector signature saved successfully");
                window.location.reload();
              } else {
                toast.error("Unable to save inspector signature");
                handleClear();
              }
      
              return res.ok;
            } 
          }
        }
      };

    return (
        <div>
            <div className={styles.main}>
                <h1 className={styles.myheading}>User Profile</h1>
                <h3>Display Name: {displayedUser.username} </h3> 
                {user ? <><h3>Signature:</h3><img src={`data:image/png;base64, ${user[0]?.inspect_signature}`} alt='Signature' width={200} height={200} /></> : null}
                {!user[0]?.inspect_signature ?
                <>
                <div className={styles.signature_container}>
                    <button className={styles.signature_clear} onClick={handleClear}>Clear</button>
                    <canvas className={styles.signature_canvas} ref={canvasRef} width={400} height={200}></canvas>
                    <div className={styles.signature_label_container}>
                        <p>Please sign above</p>
                    </div>
                </div>
                 <button className={styles.Btn} onClick={() => saveSignature()}>Save Signature</button> 
                 </>
                 : null}
            </div>
        </div>
    )
};

export default Profile;

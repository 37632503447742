import React, { useState } from 'react';
import { post } from '../../api';
import { toast } from 'react-toastify';
import styles from './PasswordReset.module.scss';

const PasswordReset = ({ history }) => {
    const [email, setEmail] = useState('');
    const submit = async (event) => {
        event.preventDefault();
        const res = await post('/password_reset', { email });
        if (res.ok)
        {
            toast.success("Password reset mail was sent. Please check your mailbox and spam folder.", res.statusText);
        }
        else if (res.status === 404)
        {
            toast.error("User not registered. Please sign up first.");
        }
        else
        {
            toast.error("Something went wrong with resetting your password. Please contact system administrator.");
        }
        return res.ok;
    };

    return (
        <div className={styles.main}>
            <input className={styles.chk} type="checkbox" id="chk" aria-hidden="true" />
            <form method="post" onSubmit={submit}>
                <div align="center">
                    <label className={styles.RegLabel} for="chk" aria-hidden="true">Reset Password</label>
                    <label className={styles.my_label}>Please enter email address</label><br/>
                    <input type="email" name="email" placeholder="Email" onChange={(e) => setEmail(e.target.value)} required/>
                    <label className={styles.my_labelRed}>Remember to check your email's spambox.</label><br/>
                </div>
                <button className={styles.RegButton} name="reset_password">Reset Password</button>
                <button className={styles.RegButton} onClick={() => history.push("/login")} name="back">Back</button>
            </form>
        </div>
    )

};

export default PasswordReset;
import React from 'react';
import './Modal.css'; 

const Modal = ({ isOpen, onClose, onContinue, content }) => {
  return (
    <div className={`modal ${isOpen ? 'open' : ''}`} onClick={onClose}>
      <div className="modal-content" onClick={(e) => e.stopPropagation()}>
        <span className="close" onClick={onClose}>&times;</span>
        <h2>Confirmation</h2>
        <p>{content}</p>
        
        <div className="button-container">
          <button className="button continue" onClick={onContinue}>Next</button>
          <button className="button cancel" onClick={onClose}>Cancel</button>
        </div>
      </div>
    </div>
  );
};

export default Modal;
import React from "react";
import { Document, Page, Text, View, StyleSheet, Image } from "@react-pdf/renderer";


const InspectionTemplate = ({ formElements, formData, jobId, settings, inspection }) => {
    const styles = StyleSheet.create({
        page: {
            flexDirection: 'row',
            backgroundColor: '#E4E4E4',
          },
        image: {
            width: '50%', 
            height: '200px',  
        },
        body: {
            padding: '10mm 10mm 5mm 10mm',
            fontSize: '11pt',
            display: 'flex',
            color: '#505050',
        },
        row: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-start',
            paddingBottom: '2mm',
            paddingTop: '2mm',
        },
        heading: {
            color: '#0B0B45',
            maxWidth: '500px;'
        },
        coverContainer: {
            display: 'flex',
            flexDirection: 'row',
            height: '100%',
        },
        coverHalfWhite: {
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: 'white',
            width: '50%',
            height: '100%',
            position: 'absolute',
            left: 0,
            top: 0,
        },
        coverHalfRed: {
            backgroundColor: 'red',
            width: '50%',
            height: '100%',
            position: 'absolute',
            right: 0,
            top: 0,
        },
        coverTitle: {
            fontSize: '24pt',
            fontWeight: 700,
            color: 'black',
            marginBottom: '20px',
            zIndex: 1, 
        },
        column: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
        },
        columnR: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-end',
            flexGrow: 1,
        },
        columnL: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
            flexGrow: 2,
            fontSize: '14pt',
        },
        header: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            marginBottom: '5mm',
        },
        headerimg: {
            marginLeft: '-5mm',
            marginTop: '-5mm',
            marginRight: '-5mm',
            width: '200mm',
        },
        center: {
            textAlign: 'center',
        },
        centered: {
            margin: 'auto',
        },
        title: {
            fontSize: '20pt',
            fontWeight: 700,
            color: '#f22300',
            marginLeft: '50px',
            marginTop: '50px',
        },
        subtitle: {
            fontSize: '20pt',
            fontWeight: 900,
            marginTop: '10px',
            marginLeft: '50px',
        },
        subsubtitle: {
            fontSize: '14pt',
            fontWeight: 900,
            marginLeft: '50px'
        },

        h1: {
            fontSize: '24pt',
            textTransform: 'uppercase',
            color: '#0081c6',
            fontWeight: 700,
        },
        h2: {
            fontSize: '18pt',
            textTransform: 'uppercase',
            color: '#005e91',
            fontWeight: 700,
        },
        h3: {
            fontSize: '14pt',
            textTransform: 'uppercase',
            color: '#47a3d3',
            fontWeight: 700,
        },
        h4: {
            fontSize: '12pt',
            textTransform: 'uppercase',
            color: '#505050',
            fontWeight: 700,
        },
        space5: {
            marginBottom: '5mm',
        },
        space2: {
            marginBottom: '2.5mm',
        },
        italic: {
            fontStyle: 'italic',
        },
        bold: {
            fontWeight: 'bold',
        },
        boldItalic: {
            fontWeight: 'bold',
        },
        footer: {
            display: 'flex',
            flexDirection: 'row',
            width: '100%',
            position: 'absolute',
            bottom: '5mm',
            marginTop: '5mm',
            marginLeft: '50px',
            fontSize: '10pt',
            color: '#bbbbbb',
            fontStyle: 'italic',
            textAlign: 'center',
        },
        tableRow: {
            padding: '2mm',
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            marginLeft: '50px',
        },
        tableCell: {
            width: '100%',
            textAlign: 'left',
            padding: '2mm',
            borderStyle: 'solid',
            borderBottom: 1,
            borderColor: '#dddddd',
            color: 'grey',
            marginLeft: '50px', 
        },
        tableHeader: {
            fontWeight: 'bold',
        },
        tableHeaderBlue: {
            fontWeight: 'bold',
            color: '#ffffff',
            backgroundColor: '#0081c6',
        },
        textRight: {
            textAlign: 'right',
        },
        colWidth7: {
            width: '7%',
        },
        colWidth10: {
            width: '10%',
        },
        colWidth15: {
            width: '15%',
        },
        colWidth40: {
            width: '40%',
        },
        colWidth48: {
            width: '48%',
        },
        colWidth60: {
            width: '60%',
        },
        colWidth55: {
            width: '55%',
        },
        colWidth85: {
            width: '85%',
        },
        bgGrey: {
            backgroundColor: '#eeeeee',
        },
        spaceNone: {
            marginTop: 0,
            marginBottom: 0,
        },
        hr: {
            border: 0,
            height: '1px',
            backgroundColor: '#ccc' 
        },
        imageContainer: {
            flexDirection: 'row',
            justifyContent: 'center',
            margin: 0,
            marginVertical: 0,
        },
        signatureContainer: {
            alignItems: 'center',
            marginRight: 10, // Adjust the spacing between signatures as needed
        },
        signatures: {
            width: '50px',
            height: '50px'
        },
        signaturesText: {
            fontSize: '14px',
            margin: 0
        },
        textContainer: {
            alignItems: 'center',
            marginBottom: '10px'
        },
        imageContainer2: {
            flexDirection: 'row',
            justifyContent: 'center',
            height: '200px'
        },
        imageHeader: {
            color: '#0B0B45',
            fontSize: '20px',
        },
        imageRow: {
            flexDirection: 'row',
            flexWrap: 'wrap',
            justifyContent: 'center',
            alignItems: 'center',
            marginTop: 10,
        },
        imageContainer3: {
            width: '40%', 
            margin: '10px',
            height: '40%',
            borderWidth: 1,
            borderColor: '#000',
            borderRadius: 5,
            overflow: 'hidden',
        },
        imageDisplay: {
            width: '100%',
            height: '90%'
        }
        
    });

    const pages = [];
    let currentPage = [];
    let headerAdded = false; // Variable to track if the header has been added
    
    const addPage = () => {
      pages.push(
        <Page key={pages.length} style={styles.page}>
          <View style={styles.columnL}>
            {headerAdded ? null : <Text style={[styles.tableRow, styles.imageHeader]}>Appendix</Text>}
            <View style={styles.imageRow}>{currentPage}</View>
          </View>
        </Page>
      );
      currentPage = [];
      headerAdded = true;
    };
    
    formElements.forEach((item, index) => {
      if (item.type === 'file' && item.value !== "''") {
        currentPage.push(
          <View key={index} style={styles.imageContainer3}>
            <Text style={[styles.tableRow, styles.heading]}>{item.label}</Text>
            <Image src={{ uri: item.value }} style={styles.imageDisplay} />
          </View>
        );
    
        if (currentPage.length === 4 || index === formElements.length - 1) {
          addPage();
        }
      }
    });

    return (
        <Document>
            <Page size="A4" wrap>
                <View style={styles.coverContainer}>   
                    <View style={styles.coverHalfWhite}>
                        <Text style={styles.coverTitle}>CKM FIRE MANAGEMENT (PTY) LTD</Text>
                    </View>
                    <View style={styles.coverHalfRed}></View>
                </View>
            </Page>
            <Page wrap>
                <View style={styles.container}>
                    {formData.map((form, index) => (
                    <>
                    <View style={styles.row}>
                        <View style={[styles.column, { flexDirection: 'row', alignItems: 'center' }]}>
                            <Image style={{ width: "250px", height: "120px" }} source={{ uri: process.env.PUBLIC_URL + '/background1.png' }} />
                            <View style={{ marginLeft: 10 }}>
                                <Text style={styles.subsubtitle}>{settings["company_name"]}</Text>
                                <Text style={styles.subsubtitle}>Registration No: {settings["reg_no"]}</Text>
                                <Text style={styles.subsubtitle}>Vat No: {settings["vat_no"]}</Text>
                                <Text style={styles.subsubtitle}>{settings["company_contacts"]}</Text>
                                <Text style={styles.subsubtitle}>Email: {settings["company_email"]}</Text>
                                <Text style={styles.subsubtitle}>{settings["company_address"]}</Text>
                            </View>
                        </View>
                    </View>
                    <Text style={styles.hr}></Text>
                    <Text style={styles.subtitle}>{form.inspection_data.displayName}</Text>
                    <View style={styles.row}>
                        <View style={styles.columnL}>
                            <Text style={[styles.tableRow, styles.heading]}>Client/Site Name:</Text>
                            <Text style={styles.tableCell}>{jobId[0]["client_name"]}</Text>
                        </View>
                    </View>
                    <View style={styles.row}>
                        <View style={styles.columnL}>
                            <Text style={[styles.tableRow, styles.heading]}>Inspector:</Text>
                            <Text style={styles.tableCell}>{jobId[0]["user"]}</Text>
                        </View>
                    </View>
                    <View style={styles.row}>
                        <View style={styles.columnL}>
                            <Text style={[styles.tableRow, styles.heading]}>Job Started:</Text>
                            <Text style={styles.tableCell}>{jobId[0]["start_time"]}</Text>
                        </View>
                    </View>
                    <View style={styles.row}>
                        <View style={styles.columnL}>
                            <Text style={[styles.tableRow, styles.heading]}>Job Ended:</Text>
                            <Text style={styles.tableCell}>{jobId[0]["end_time"]}</Text>
                        </View>
                    </View>
                    </>
                    ))}
                </View>
            </Page>
            <Page wrap>
            {formElements.map((item, index) => (
                <View style={styles.row} key={index}>
                    <View style={styles.columnL}>
                        {item.type !== 'file' && (
                            item.type !== 'button' && item.label !== 'Client' && item.label !== 'Inspector' && item.label !== 'Start Time' && item.label !== 'End Time' && (
                                <>
                                    <Text style={[styles.tableRow, styles.heading]} minPresenceAhead={40}>{item.label}</Text>
                                    <Text style={styles.tableCell} minPresenceAhead={40}>{item.value}</Text>
                                </>
                            )
                        )}
                      
                        {item.type === 'file' && item.value !== "''" && (
                            <>
                                <Text style={[styles.tableRow, styles.heading]}>{item.label}</Text>
                                <View style={styles.imageContainer}>
                                    <Image src={{ uri: item.value }} style={styles.image} />
                                </View>
                            </>
                        )}
                    </View>
                </View> 
            ))}
            <>
                <View style={[styles.row, styles.imageContainer]}>
                    <View style={styles.signatureContainer}>
                        <Image src={{ uri: `data:image/png;base64,${inspection?.client_signature || null}` }} style={styles.signatures} />
                        <View style={styles.textContainer}>
                            <Text style={styles.signaturesText}>Client Signature</Text>
                        </View>
                    </View>
        
                    <View style={{ width: "200px" }}></View>
        
                    <View style={styles.signatureContainer}>
                        <Image src={{ uri: `data:image/png;base64,${inspection?.inspect_signature || null}` }} style={styles.signatures} />
                        <View style={styles.textContainer}>
                            <Text style={styles.signaturesText}>Inspector Signature</Text>
                        </View>
                    </View>
                </View>
            </>
            </Page>
            {pages}
        </Document>
    )
};
export default InspectionTemplate; 
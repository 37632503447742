import React, { useEffect, useState } from 'react';
import styles from './Clients.module.scss';
import { post, get } from '../../api';
import { toast } from 'react-toastify';
import { useHistory } from "react-router-dom";
import { getClientsInfo } from '../../dexieDB';
import  secureLocalStorage  from  "react-secure-storage";
import { Online } from 'react-detect-offline';


const Clients = () => {
    const [clients, setClients] = useState([]);
    const [search_text, setSearchText] = useState('');
    const history = useHistory();
    const userid = JSON.parse(secureLocalStorage.getItem('user')).id;
    const isOnline = navigator.onLine;
  
    useEffect(() => {
        const fetchData = async () => {
            try {
                let clientsData;
                if (isOnline) {
                    console.log("Fetching clients online...");
                    const resp = await get(`/client_list`);
                    if (resp.ok) {
                        const clients = await resp.json();
                        setClients(clients);
                    } else {
                        toast.error("Failed to fetch clients: " + resp.statusText);
                    }
                } else {
                    console.log("Fetching clients offline...");
                    clientsData = await getClientsInfo();
                    if (clientsData.length === 0) {
                        toast.error("No clients available offline.");
                    } else {
                        setClients(clientsData);
                    }
                }
            } catch (error) {
                toast.error("Error fetching clients: " + error.message);
            }
        };
    
        fetchData();
    }, [isOnline]);
    
    const search_client = async (event) => {
        event.preventDefault();
        const res = await post('/client_list_search', { search_text });
        if (res.ok)
        {
            setClients(await res.json());
        }
        else
        {
            toast.error("Something went wrong with searching through the clients. Please contact system administrator.");
        }
        return res.ok;
    };

    return (
        <div>
            <div className={styles.main}>
                <h1 className={styles.myheading}>Clients</h1>
                <form method="post">
                    <Online>
                        <input className={styles.my_edit} type="text" value={search_text} onChange={(e) => setSearchText(e.target.value)}></input><br/><br/>
                        <input type="submit" value="Search" onClick={search_client}/>
                        <input type="submit" value="New" onClick={() => history.push("/client_new")}/>
                    </Online>
                        <table className={styles.my_table}>
                            <thead>
                                <tr>
                                    <th>Client ID</th>
                                    <th>Client</th>
                                    <th>Contact Person</th>
                                    <th>Contact Number</th>
                                    <th>Email</th>
                                </tr>
                            </thead>
                            <tbody>
                                {clients.map((client) => (
                                    <tr key={client.clientid}
                                        onClick={() => history.push({ pathname: "/forms", state: { client_id: client.clientid, client_name: client.client_name, user_id: userid }})}>
                                        <td>{client.clientid}</td>
                                        <td>{client.client_name}</td>
                                        <td>{client.contact_person}</td>
                                        <td>{client.contact_number}</td>
                                        <td>{client.email}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table><br/>
                </form>
            </div>
        </div>
    )
};

export default Clients;

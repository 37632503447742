import React, { useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import styles from './NavBar.module.scss';
import { useUser } from '../../context';
import secureLocalStorage from 'react-secure-storage';
import { useHistory } from "react-router-dom";
import { Offline, Online } from 'react-detect-offline';
import { removeLocalStorageItem } from '../utils/Utils';

const Navbar = () => {
    const { user, setUser } = useUser([]);
    const history = useHistory();

    useEffect(() => {
        const fetchOfflineUser = async () => {
            const storedUser = secureLocalStorage.getItem('user');
            if (!user && storedUser) {
                setUser(JSON.parse(storedUser));
            }
        };

        fetchOfflineUser();
    }, [user, setUser]);

    const handleLogout = async (event) => {
        event.preventDefault();
        secureLocalStorage.removeItem('user');
        removeLocalStorageItem('logged_in');
        history.push("/login");
    };
    const displayedUser = JSON.parse(secureLocalStorage.getItem('user'));

    return (
        <div className={styles.Nav}>
            <Online>
                <div className={styles.NavHeading}>
                    <p>Welcome!!! {displayedUser.username} (Online)</p>
                </div>
            </Online>
            <Offline>
                <div className={styles.NavHeading}>
                    <p>Welcome!!! {displayedUser.username} (Offline)</p>
                </div>
            </Offline>
            <div className={styles.NavLinks}>
                <NavLink activeClassName={styles.active} exact to="/home">
                    <i className="material-icons">home</i>Home
                </NavLink>
                {displayedUser.is_inspect === 1 ? (
                    <>
                        <NavLink activeClassName={styles.active} exact to="/clients">
                            <i className="material-icons">add_circle</i>Clients
                        </NavLink>
                        <NavLink activeClassName={styles.active} exact to="/jobs">
                            <i className="material-icons">add_circle</i>Jobs
                        </NavLink>
                    </>
                ) : null}
                {/* <NavLink activeClassName={styles.active} exact to="/synced_data">
                    <i className="material-icons">sync</i>Offline Sync
                </NavLink> */}
                {displayedUser.is_sysadmin === 1 ? (
                    <>
                    <Online>
                        <NavLink activeClassName={styles.active} exact to="/settings">
                            <i className="material-icons">settings</i>System Settings
                        </NavLink>
                    </Online>
                    </>
                ) : null}
                {displayedUser.is_akks === 1 ? (
                    <>
                        <NavLink activeClassName={styles.active} exact to="/inspection_category">
                            <i className="material-icons">settings</i>Inspection Category
                        </NavLink>
                        <Online>
                            <NavLink activeClassName={styles.active} exact to="/form-builder">
                                <i className="material-icons">edit</i>Form Builder
                            </NavLink>
                        </Online>
                    </>
                ) : null}
                {displayedUser.is_sysadmin === 1 ? (
                    <>
                        <Online>
                            <NavLink activeClassName={styles.active} exact to="/sysadmin">
                                <i className="material-icons">people</i>SysAdmin
                            </NavLink>
                        </Online>
                    </>
                ) : null}
                <NavLink activeClassName={styles.active} exact to="/profile">
                    <i className="material-icons">people</i>Profile
                </NavLink>
                <NavLink activeClassName={styles.active} exact to="/logout" onClick={handleLogout}>
                    <i className="material-icons">logout</i>Logout
                </NavLink>
            </div>
        </div>
    );
}

export default Navbar;
import React, { useState, useEffect, useRef } from "react";
import styles from './Inspection.module.scss';
import { useHistory } from "react-router-dom";
import SignaturePad from 'signature_pad';
import { toast } from 'react-toastify';
import { post } from "../../api";
import { updateClientSignature } from '../../dexieDB';


const ClientSignature = () => {
    const canvasRef = useRef(null);
    const signaturePadRef = useRef(null);
    const history = useHistory();
    const templateName = history.location.state.templateName;
    const [signature2, setSignature2] = useState(false);
    const [signatureName, setSignatureName] = useState('');
    const jobId = history.location.state.jobId;
    const isOnline = navigator.onLine;

    useEffect(() => {
        const canvas = canvasRef.current;
        const signaturePad = new SignaturePad(canvas);

        signaturePadRef.current = signaturePad;
        signaturePad.minWidth = 1;
        signaturePad.maxWidth = 3;
    
        return () => {
          signaturePad.clear();
        };
      }, []);
    
    const handleClear = () => {
        const signaturePad = new SignaturePad(canvasRef.current);
        signaturePad.clear();
    };

    const GoToFinalSignature = async() => {
        if (!signatureName || signatureName === undefined) {
            toast.error("No client and surname have been entered.");
        }
        else {
            GoToInspectSignature();
        }
    };

    const GoToInspectSignature = async () => {
        if (signaturePadRef.current) {
          const signatureData = signaturePadRef.current.toDataURL();
      
          if (signaturePadRef.current.isEmpty()) {
            toast.error("No client signature has been drawn");
          } else {
            toast.dismiss();
            setSignature2(true);
      
            if (isOnline) {
                const res = await post('/inspection_save_signature', { clientSignature: signatureData.split(',')[1], jobId, signatureName });
      
              if (res.ok) {
                history.push({
                    pathname: '/inspection_confirm', 
                    state: 
                      { 
                        jobId: jobId,
                        templateName: ''
                    }
                })
                toast.success("Client signature saved successfully");
              } else {
                toast.error("Unable to save inspector signature");
                handleClear();
              }
      
              return res.ok;
            } else {
              await updateClientSignature({
                jobId,
                clientSignature: signatureData.split(',')[1],
                signatureName: signatureName
              });
              history.push({
                pathname: '/clients', 
                state: 
                  { 
                    jobId: jobId,
                    templateName: ''
                }
                })
              toast.success("client signature saved locally. Will sync when back online.");
            }
          }
        }
    };

    const GoToMultiSign = () => {
        history.push({
            pathname: '/multi_sign', 
            state: 
              { 
                jobId: jobId
            }
        })
    };

    return (
        <div className={styles.main}>
        {!signature2 ?
            <div className={styles.form}>
                <p>I hereby confirm the completion of the {templateName}</p>
                <p>Client Name And Surname</p>
                <input type="text" className={styles.client_input} onChange={(e) => setSignatureName(e.target.value)}/>
                <br />
                <p>Client Signature</p>
                <div className={styles.signature_container}>
                <button className={styles.signature_clear} onClick={handleClear}>Clear</button>
                    <canvas className={styles.signature_canvas} ref={canvasRef} width={400} height={200}></canvas>
                    <div className={styles.signature_label_container}>
                        <p>Please sign above</p>
                    </div>
                </div>
                <br />
                <button className={styles.btn_signature} onClick={() => GoToMultiSign()}>Sign For Multiple Jobs</button>
                <br />
                <br />
                <div className={styles.btn_container}>
                    <button className={styles.btn_signature} onClick={() => GoToFinalSignature()}>Next</button>
                    <button className={styles.btn_signature}>Back</button>
                </div>
            </div>
        : null
        }
        </div>
    )
};

export default ClientSignature;
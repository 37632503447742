import React, { useEffect, useState } from 'react';
import styles from './InspectionCategory.module.scss';
import { post, get } from '../../api';
import { toast } from 'react-toastify';
import { useHistory } from "react-router-dom";
import { getInspectionCategoryInfo } from '../../dexieDB';

const InspectionCategory = () => {
    const [inspection_categories, setInspectionCategories] = useState([]);
    const [search_text, setSearchText] = useState('');
    const history = useHistory();
    const isOnline = navigator.onLine;

    useEffect(() => {
        if (isOnline) {
            const get_inspection_categories = async () => {
            const resp = await get(`/inspection_category_list`);
            if (resp.ok) {
                setInspectionCategories(await resp.json());
            }
        };
        get_inspection_categories();
        } else {
            const fetchData = async () => {
            try {
                console.log("Fetching inspection categories offline...");
                const categoriesData = await getInspectionCategoryInfo();
                setInspectionCategories(categoriesData);
            } catch (error) {
                console.error("Error fetching categories from offline DB:", error);
            }};
            fetchData();
        } 
    }, [isOnline]);

    const search_inspection_categories = async (event) => {
        event.preventDefault();
        const res = await post('/inspection_categories_list_search', { search_text });
        if (res.ok)
        {
            setInspectionCategories(await res.json());
        }
        else
        {
            toast.error("Something went wrong with searching through the inspection list. Please contact system administrator.");
        }
        return res.ok;
    };

    return (
        <div>
            <div className={styles.main}>
                <h1 className={styles.myheading}>Inspection Categories</h1>
                <form method="post">
                    <input className={styles.my_edit} type="text" value={search_text} onChange={(e) => setSearchText(e.target.value)}></input><br/><br/>
                    <input type="submit" value="Search" onClick={search_inspection_categories}/>
                    <input type="submit" value="New" onClick={() => history.push("/inspection_cat_new")}/>
                    <table className={styles.my_table}>
                        <thead>
                            <tr>
                                <th>Category ID</th>
                                <th>Category</th>
                            </tr>
                        </thead>
                        <tbody>
                            {inspection_categories.map((inspection_category) => (
                                <tr key={inspection_category.id}
                                    onClick={() => history.push(
                                        {
                                            pathname: "/inspection_cat_edit", state: { inspection_category_id: inspection_category.id,
                                                                                        inspection_categoryname: inspection_category.category}
                                        }
                                    )}>
                                    <td>{inspection_category.id}</td>
                                    <td>{inspection_category.category}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table><br/>
                </form>
            </div>
        </div>
    )
};

export default InspectionCategory;

import React, { useEffect, useState } from 'react';
import styles from './SysAdmin.module.scss';
import { post } from '../../api';
import { useHistory } from "react-router-dom";


const Sysadmin = () => {
    const [users, setUsers] = useState([]);
    const history = useHistory();

    useEffect(() => {
        const get_users = async () => {
            const resp = await post(`/user`, { filterlist: `WHERE fullname IS NOT NULL` });
            if (resp.ok) {
                setUsers(await resp.json());
            }
        };
        get_users();
    }, []);

    return (
        <div>
            <form method="post">
                <div className={styles.main}>
                    <h1 className={styles.myheading}>SysAdmin User Management</h1>
                    <table className={styles.my_table}>
                        <thead>
                            <tr>
                                <th>ID</th>
                                <th>Name</th>
                                <th>Email</th>
                                <th>is_admin</th>
                                <th>is_inspect</th>
                                <th>is_sysadmin</th>
                                <th>Status</th>
                            </tr>
                        </thead>
                        <tbody>
                            {users.map((user) => (
                                <tr key={user.id} onClick={() => history.push(
                                    {
                                        pathname: "/sysadmin_edit", state: { userid: user.id }
                                    }
                                )}>
                                    <td>{user.id}</td>
                                    <td>{user.fullname}</td>
                                    <td>{user.username}</td>
                                    <td>{user.is_admin}</td>
                                    <td>{user.is_inspect}</td>
                                    <td>{user.is_sysadmin}</td>
                                    <td>{user.status}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table><br/>
                </div>
            </form>
        </div>
    )
};

export default Sysadmin;

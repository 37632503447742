import React, { useEffect, useState } from 'react';
import styles from './Clients.module.scss';
import { get, post } from '../../api';
import { toast } from 'react-toastify';
import { useHistory } from "react-router-dom";
import  secureLocalStorage  from  "react-secure-storage";
import { getJobsInfo, getJobsToDisplay } from '../../dexieDB';
import InspectionTemplate from "../inspection/InspectionTemplate";
import ReactPDF from '@react-pdf/renderer';


const Jobs = () => {
    const [jobs, setJobs] = useState([]);
    const [jobInfo, setJobsInfo] = useState([]);
    const [completedJobs, setCompletedJobs] = useState([]);
    const history = useHistory();
    const userid = JSON.parse(secureLocalStorage.getItem('user')).id;
    const jobId = history.location.state?.jobId;
    const isOnline = navigator.onLine;
    const [formData, setFormData] = useState([]);
    const [formElements, setFormElements] = useState([]);
    const [settings, setSettings] = useState({});
    const [loading, setLoading] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);

    const openModal = () => setIsModalOpen(true);
    const closeModal = () => setIsModalOpen(false);

    const Modal = ({ onClose }) => {
        return (
            <div className={styles.modal} onClick={onClose}>
                <div className={styles.modal_content} onClick={(e) => e.stopPropagation()}>
                    <h2>Confirmation</h2>
                    <p>Are you sure you want to submit this job?</p>
                    
                    <div className={styles.button_container}>
                        <button className={styles.modal_continue} onClick={send_job_email}>Submit</button>
                        <button className={styles.modal_cancel} onClick={onClose}>Cancel</button>
                    </div>
                    {loading && (
                    <div className={styles.loaderContainer}>
                        <div className={styles.loader}></div>
                        <span>Loading...</span>
                    </div>
                    )}
                </div>
            </div>
        );
    };

    const send_job_email = async () => {
        console.log("formsss", formData)
        console.log("formvalues", formElements)
        setLoading(true);
        const signedPdf = await ReactPDF.pdf(<InspectionTemplate formData={formData} formElements={formElements} jobId={jobInfo} settings={settings} inspection={formData?.[0]}/>).toBlob();
        
        if(signedPdf){
            const pdfs = signedPdf;
            new Promise((resolve) => {
              var reader = new FileReader();
              reader.readAsDataURL(pdfs); 
              reader.onloadend = function() {
                (async function() {
                    var base64data = reader.result;
                    const base64 = base64data?.split(',')[1];
                    try {
                      const res = await post('/inspection_confirm', { "signedPDF": base64, "templateName": formData[0]?.template_name ?? null, "jobInfo": jobInfo });

                      if (res.ok) {
                        toast.success("job has been emailed successfully.");
                        closeModal();
                      } else {
                        setLoading(false);
                        toast.error("Unable to send job email, please try again.");
                      }
                    } catch (error) {
                      console.error("Error sending job email:", error);
                      toast.error("Unable to send job email, please try again.");
                    } finally {
                        setLoading(false);
                    }
                  })();
              }
            })
        };
    };

    const getJob = async(jobtype, jobid) => {
        if (isOnline) {
            const resp = await post(`/jobs_info`, { jobid });
            if (resp.ok) {
                setJobsInfo(await resp.json());
                history.push(`/inspection_view/${jobtype}/${jobid}`);
            }
        } else {
            const fetchData = async () => {
                try {
                    const jobsData = await getJobsInfo();
                    setJobsInfo(jobsData);

                    if (jobsData) {
                        history.push(`/inspection_view/${jobtype}/${jobid}`);
                    }
                } catch (error) {
                    toast.error("Error fetching jobs from Offline DB:", error);
                }};
            fetchData();
        }
    };

    useEffect(() => {
        if (jobId > 0) {
            toast.error("You have a running job. Close job first.");
        }
    }, [jobId]);

    useEffect(() => {
        const getJobs = async () => {
            try {
                if (isOnline) {
                    const resp = await post(`/jobs_list`, { userid, filterlist: '' });
                    if (resp.ok) {
                        const data = await resp.json();
                        setJobs(data);
                    } else {
                        toast.error("Unable to get jobs list");
                    }
                } else {
                    try {
                        const jobsData = await getJobsToDisplay();
                        setJobs(jobsData);
                        if (jobsData.length === 0) {
                            toast.error("No jobs available offline.");
                        } else {
                            setJobs(jobsData);
                        }
                    } catch (error) {
                        toast.error("Error fetching jobs from Offline DB:", error);
                    }
                }
            } catch (error) {
                console.error("Error fetching jobs:", error);
                toast.error("An error occurred while fetching jobs");
            }
        };
    
        getJobs();
    }, [isOnline, userid]);

    useEffect(() => {
        const getCompletedJobs = async () => {
            try {
                if (isOnline) {
                    const resp = await post(`/jobs_list`, { userid, filterlist: 'where status = 2' });
                    if (resp.ok) {
                        const data = await resp.json();
                        setCompletedJobs(data);
                    } else {
                        toast.error("Unable to get jobs list");
                    }
                } else {
                    return null
                }
            } catch (error) {
                console.error("Error fetching jobs:", error);
            }
        };
    
        getCompletedJobs();
    }, [isOnline, userid]);

    useEffect(() => {
        const get_forms = async () => {
            const resp = await post(`/inspection_list`, { id: jobId });
            if (resp.ok) {
                setFormData(await resp.json());
            }
        };
        get_forms();
    }, [jobId]);

    useEffect(() => {
        formData.forEach((item) => {
            const jsonData = item.inspection_data;
            setFormElements(jsonData.fields);
        });
    }, [formData]);

    useEffect(() => {
        const get_settings = async () => {
            const resp = await get(`/setting_info`);
            if (resp.ok) {
                setSettings(await resp.json());
            }
        };
        get_settings();
    }, [jobId]);
    
    return (
        <div>
            <div className={styles.main}>
                <h1 className={styles.myheading}>Jobs</h1>
                <input className="btn-primary" type="submit" name="today" value="Today Only"></input>
                <input className="btn-primary" type="submit" name="all_jobs" value="All My Jobs"></input>
                <input className="btn-primary" type="submit" name="jobs_running" value="Current Active Jobs"></input>
                <input className="btn-primary" type="submit" name="jobs_sigs" value="Needs Signature"></input>
                <input className="btn-primary" type="submit" name="jobs_mailed" value="To Be Mailed"></input>
                <button className={styles.button_finalize} type="submit" onClick={() => openModal()}>Finalize Jobs</button>
                    {isModalOpen ?
                    <Modal isOpen={isModalOpen} onClose={closeModal} />
                    : null
                    }
                    <table className={styles.my_table}>
                        <thead>
                            <tr>
                            <th>Job ID</th>
                            <th>Client</th>
                            <th>Job Type</th>
                            <th>Valve Chamber Area</th>
                            <th>Valve Chamber</th>
                            <th>Start Time</th>
                            <th>End Time</th>
                            <th>Status</th>
                            </tr>
                        </thead>
                        <tbody>
                            {jobs.map((job) => (
                                <tr key={jobs.jobid}
                                    onClick={() => history.push(getJob(job.job_type, job.jobid))}
                                    >
                                    <td>{job.jobid}</td>
                                    <td>{job.client_name}</td>
                                    <td>{job.job_type}</td>
                                    <td>{job.valve_description}</td>
                                    <td>{job.valve_chamber}</td>
                                    <td>{job.start_time}</td>
                                    <td>{job.end_time}</td>
                                    <td>{job.job_status}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table><br/>
            </div>
        </div>
    )
};

export default Jobs;

import React, { useState, useEffect } from 'react';
import styles from './InspectionCategory.module.scss';
import { post, get } from '../../api';
import { toast } from 'react-toastify';
import { useHistory } from "react-router-dom";

const InspectionCatEdit = () => {
  const [category_name, setCategoryName] = useState('');
  const [isChecked, setIsChecked] = useState(true);
  const history = useHistory();
  const inspection_category_id = history.location.state.inspection_category_id;
  const inspection_categoryname = history.location.state.inspection_categoryname;

  const submit = async (event) => {
    event.preventDefault();
    const res = await post('/inspection_cat_edit', { inspection_category_id, category_name , isChecked});
    if (res.ok)
    {
        toast.success("Category has been saved.", res.statusText);
        history.push('/inspection_category');
    }
    else
    {
        toast.error("Something went wrong with saving the settings. Please contact system administrator.");
    }
    return res.ok;
  };

  useEffect(() => {
    const get_name = async () => {
      if (inspection_categoryname !== undefined) {
        setCategoryName(inspection_categoryname);
      }
    };
    get_name();
  }, []);

  return (
    <div>
        <form method="post" onSubmit={submit}>
            <div className={styles.main}>
                <h1 className={styles.myheading}>Edit Inspection Category</h1>
                <label className={styles.my_label}>Category Name:</label><br/>
                <input className={styles.my_edit} type="text" value={category_name} onChange={(e) => setCategoryName(e.target.value)}/><br/>
                <label className={styles.my_label}>
                  <input type="checkbox" checked={isChecked} onChange={() => setIsChecked(!isChecked)}/>
                  Active
                </label><br/>
            </div>
            <div className={styles.main}>
                <input type="submit" className={styles.btnOk} value="Save"/>
            </div>
        </form>
    </div>
  )
};
export default InspectionCatEdit;
import React, { useEffect, useState } from 'react';
import { useUser } from '../../context';
import { post } from '../../api';
import styles from './Login.module.scss';
import { toast } from 'react-toastify';
import secureLocalStorage from 'react-secure-storage';
import db, { addUser, getUser } from '../../dexieDB';
import { setLocalStorageItem } from '../utils/Utils';

const Login = ({ history }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const { setUser } = useUser();
  const getParams = history.location.search.split('?returl=')[1];

  useEffect(() => {
    db.open().catch((err) => {
      console.error("Open failed: " + err.stack);
    });
  }, []);

  const submit = async (event) => {
    event.preventDefault();

    if (navigator.onLine) {
      const res = await post('/user/login', { email, password });

      if (res.ok) {
        const json = await res.json();

        if (json.status === "match") {
          const userData = {
            id: json.id,
            username: json.username,
            is_inspect: json.is_inspect,
            is_admin: json.is_admin,
            is_sysadmin: json.is_sysadmin,
            is_akks: json.is_akks,
            current_job: json.current_job,
            email: json.email
          };

          setUser(userData);
          setLocalStorageItem('logged_in', true);
          toast.dismiss();

          if (json.current_job > 0) {
            history.push({
              pathname: '/jobs',
              state: { jobId: json.current_job },
            });
          } else {
            history.push(getParams || '/home');
          }

          secureLocalStorage.setItem('user', JSON.stringify(userData));
          await addUser({ email, password, userData });

        } else {
          toast.error("Username doesn't exist or incorrect password");
        }
      } else {
        toast.error("Username doesn't exist or incorrect password");
      }

      return res.ok;
    } else {
      const user = await getUser(email, password);

      if (user) {
        const userData = user.userData;
        setUser(userData);
        setLocalStorageItem('logged_in', true);

        if (userData.current_job > 0) {
          history.push({
            pathname: '/jobs',
            state: { jobId: userData.current_job },
          });
        } else {
          history.push(getParams || '/home');
        }

      } else {
        toast.error("Username doesn't exist or incorrect password");
      }
    }
  };

  return (
    <div className={styles.FormWrapper}>
      <div id="formContent">
        <h2 className="active">Sign In</h2>

        <div className="fadeIn first">
          <img src={process.env.PUBLIC_URL + '/favicon.ico'} id="icon" alt="User Icon" />
        </div>

        <form className="login-form" method="post" onSubmit={submit}>
          <input type="email" id="login" className="fadeIn second" name="login" placeholder="email address" onChange={(e) => setEmail(e.target.value)} />
          <input type="password" id="password" className="fadeIn third" name="password" placeholder="password" onChange={(e) => setPassword(e.target.value)} />
          <input type="submit" name="btnlogin" className="fadeIn fourth" value="Log In" />
        </form>

        <div id="formFooter">
          <a className="underlineHover" href="javascript:void(0);" onClick={() => history.push("/password_reset")}>Forgot Password</a><br />
          <a className="underlineHover" href="javascript:void(0);" onClick={() => history.push("/register")}>Register</a>
        </div>
      </div>
    </div>
  );
};

export default Login;

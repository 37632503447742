import React, { useState } from 'react';
import { post } from '../../api';
import { toast } from 'react-toastify';
import styles from './RequestPassword.module.scss';
import { useParams } from 'react-router-dom';

const RequestPassword = ({ history }) => {
    const { email_code } = useParams();
    const [password, setPassword] = useState('');
    const [password2, setPassword2] = useState('');
    const submit = async (event) => {
        event.preventDefault();
        const res = await post('/request_password', { email_code, password, password2 });
        if (res.ok)
        {
            toast.success("Your password was changed.", res.statusText);
        }
        else if (res.status === 400)
        {
            toast.error("The password and confirm password does not match.");
        }
        else if (res.status === 404)
        {
            toast.error("Email code not found.");
        }
        else
        {
            toast.error("Something went wrong with resetting your password. Please contact system administrator.");
        }
        return res.ok;
    };

    return (
        <div className={styles.main}>
            <input className={styles.chk} type="checkbox" id="chk" aria-hidden="true" />
            <form method="post" onSubmit={submit}>
                <div align="center">
                    <label className={styles.RegLabel} for="chk" aria-hidden="true">Change Password</label>
                    <label className={styles.my_label}>New Password</label><br/>
                    <input type="password" name="password" onChange={(e) => setPassword(e.target.value)} required/>
                    <label className={styles.my_label}>Confirm Password</label><br/>
                    <input type="password" name="password2" onChange={(e) => setPassword2(e.target.value)} required/>
                </div>
                <button className={styles.RegButton} name="reset_password">Change Password</button>
                <button className={styles.RegButton} onClick={() => history.push("/login")} name="back">Back</button>
            </form>
        </div>
    )

};

export default RequestPassword;
import React, { useState, useEffect } from "react";
import styles from './Inspection.module.scss';
import { useHistory } from "react-router-dom";
import { toast } from 'react-toastify';
import { get, post } from "../../api";
import InspectionTemplate from "./InspectionTemplate";
import ReactPDF from '@react-pdf/renderer';


const InspectionConfirm = () => {
    const history = useHistory();
    const jobId = history.location.state.jobId;
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [formData, setFormData] = useState([]);
    const [formElements, setFormElements] = useState([]);
    const [jobInfo, setJobInfo] = useState({});
    const [loading, setLoading] = useState(false);
    const [settings, setSettings] = useState({});

    const GoToClientSignature = () => {
        history.push({
            pathname: '/client_signature', 
            state: 
              { 
                jobId: jobId,
                templateName: formData[0]?.template_name
            }
        })
    };

    const GoToClients = () => {
        history.push({
            pathname: '/clients', 
            state: 
              { 
                jobId: jobId
            }
        })
    };

    const openModal = () => setIsModalOpen(true);
    const closeModal = () => setIsModalOpen(false);
    
    const Modal = ({ onClose }) => {
        return (
            <div className={styles.modal} onClick={onClose}>
                <div className={styles.modal_content} onClick={(e) => e.stopPropagation()}>
                    <h2>Confirmation</h2>
                    <p>Are you sure you want to submit this job?</p>
                    
                    <div className={styles.button_container}>
                        <button className={styles.modal_continue} onClick={send_job_email}>Submit</button>
                        <button className={styles.modal_cancel} onClick={onClose}>Cancel</button>
                    </div>
                    {loading && (
                    <div className={styles.loaderContainer}>
                        <div className={styles.loader}></div>
                        <span>Loading...</span>
                    </div>
                    )}
                </div>
            </div>
        );
    };

    const send_job_email = async () => {
        setLoading(true);
        const signedPdf = await ReactPDF.pdf(<InspectionTemplate formData={formData} formElements={formElements} jobId={jobInfo} settings={settings} inspection={formData?.[0]}/>).toBlob();
        
        if(signedPdf){
            const pdfs = signedPdf;
            new Promise((resolve) => {
              var reader = new FileReader();
              reader.readAsDataURL(pdfs); 
              reader.onloadend = function() {
                (async function() {
                    var base64data = reader.result;
                    const base64 = base64data?.split(',')[1];
                    try {
                      const res = await post('/inspection_confirm', { "signedPDF": base64, "templateName": formData[0]?.template_name ?? null, "jobInfo": jobInfo });

                      if (res.ok) {
                        toast.success("job has been emailed successfully.");
                        closeModal();
                        GoToClients();
                      } else {
                        setLoading(false);
                        toast.error("Unable to send job email, please try again.");
                      }
                    } catch (error) {
                      console.error("Error sending job email:", error);
                      toast.error("Unable to send job email, please try again.");
                    } finally {
                        setLoading(false);
                    }
                  })();
              }
            })
        };
    };

    useEffect(() => {
        const get_forms = async () => {
            const resp = await post(`/inspection_list`, { id: jobId });
            if (resp.ok) {
                setFormData(await resp.json());
            }
        };
        get_forms();
    }, [jobId]);

    useEffect(() => {
        const get_job_info = async () => {
            const resp = await post(`/jobs_info`, { jobid: jobId });
            if (resp.ok) {
                setJobInfo(await resp.json());
            }
        };
        get_job_info();
    }, [jobId]);

    useEffect(() => {
        const get_settings = async () => {
            const resp = await get(`/setting_info`);
            if (resp.ok) {
                setSettings(await resp.json());
            }
        };
        get_settings();
    }, [jobId]);

    useEffect(() => {
        formData.forEach((item) => {
            const jsonData = item.inspection_data;
            setFormElements(jsonData.fields);
        });
    }, [formData]);
 
    return (
        <>
        <div className={styles.main}>
            <div className={styles.form}>
                <div className={styles.btn_container}>
                    <button className={styles.btn_signature} onClick={() => openModal()}>Confirm Final</button>
                        {isModalOpen ?
                        <Modal isOpen={isModalOpen} onClose={closeModal} />
                        : null
                    }
                    <button className={styles.btn_signature} onClick={() => GoToClientSignature()}>Back</button>
                    <button className={styles.btn_signature} onClick={() => GoToClients()}>Back to Clients</button>
                </div>
            </div>
        </div>
        </>
    )
};

export default InspectionConfirm;
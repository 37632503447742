import React, { useEffect, useState } from 'react';
import styles from './Settings.module.scss';
import { post, get } from '../../api';
import { toast } from 'react-toastify';

const Settings = () => {
    const [smtp_server, setSMTPServer] = useState('');
    const [smtp_port, setSMTPPort] = useState('');
    const [smtp_username, setSMTPUsername] = useState('');
    const [smtp_password, setSMTPPassword] = useState('');

    const [messageboard, setMessageboard] = useState('');
    const [company_name, setCompanyName] = useState('');
    const [reg_no, setRegno] = useState('');
    const [vat_no, setVatno] = useState('');
    const [company_contacts, setContactno] = useState('');
    const [company_email, setEmail] = useState('');
    const [company_address, setAddress] = useState('');
    const [site_url, setUrl] = useState('');

    const [approver, setApprover] = useState('');
    const [receive_jobs, setJobsReceive] = useState('');

    const submit = async (event) => {
        event.preventDefault();
        const res = await post('/settings', { smtp_server, smtp_port, smtp_username, smtp_password, messageboard, company_name, reg_no
                                                , vat_no, company_contacts, company_email, company_address, site_url, approver, receive_jobs });
        if (res.ok)
        {
            toast.success("Settings updated successfully.", res.statusText);
        }
        else
        {
            toast.error("Something went wrong with saving the settings. Please contact system administrator.");
        }
        return res.ok;
    };

    useEffect(() => {
        const get_settings = async () => {
            const resp = await get(`/setting_info`);
            if (resp.ok)
            {
                const x = await resp.json();
                if (x && Object.keys(x).length > 0)
                {
                    if (x["smtp_server"] !== undefined) {
                        setSMTPServer(x["smtp_server"]);
                    }
                    if (x["smtp_port"] !== undefined) {
                        setSMTPPort(x["smtp_port"]);
                    }
                    if (x["smtp_username"] !== undefined) {
                        setSMTPUsername(x["smtp_username"]);
                    }
                    if (x["smtp_password"] !== undefined) {
                        setSMTPPassword(x["smtp_password"]);
                    }

                    if (x["messageboard"] !== undefined) {
                        setMessageboard(x["messageboard"]);
                    }
                    if (x["company_name"] !== undefined) {
                        setCompanyName(x["company_name"]);
                    }
                    if (x["reg_no"] !== undefined) {
                        setRegno(x["reg_no"]);
                    }
                    if (x["vat_no"] !== undefined) {
                        setVatno(x["vat_no"]);
                    }
                    if (x["company_contacts"] !== undefined) {
                        setContactno(x["company_contacts"]);
                    }
                    if (x["company_email"] !== undefined) {
                        setEmail(x["company_email"]);
                    }
                    if (x["company_address"] !== undefined) {
                        setAddress(x["company_address"]);
                    }
                    if (x["site_url"] !== undefined) {
                        setUrl(x["site_url"]);
                    }

                    if (x["approver"] !== undefined) {
                        setApprover(x["approver"]);
                    }
                    if (x["receive_jobs"] !== undefined) {
                        setJobsReceive(x["receive_jobs"]);
                    }
                }
            }
        };
        get_settings();
    }, []);

    return (
        <div>
            <form method="post" onSubmit={submit}>
                <div className={styles.main}>
                    <h1 className={styles.myheading}>SMTP Settings</h1>
                    <label className={styles.my_label}>SMTP Server:</label><br/>
                    <input className={styles.my_edit} type="text" value={smtp_server} onChange={(e) => setSMTPServer(e.target.value)} required/><br/>
                    <label className={styles.my_label}>SMTP Port:</label><br/>
                    <input className={styles.my_edit} type="text" value={smtp_port} onChange={(e) => setSMTPPort(e.target.value)} required/><br/>
                    <label className={styles.my_label}>SMTP Username:</label><br/>
                    <input className={styles.my_edit} type="text" value={smtp_username} onChange={(e) => setSMTPUsername(e.target.value)} required/><br/>
                    <label className={styles.my_label}>SMTP Password:</label><br/>
                    <input className={styles.my_edit} type="text" value={smtp_password} onChange={(e) => setSMTPPassword(e.target.value)} required/><br/><br/>
                </div>

                <div className={styles.main}>
                    <h1 className={styles.myheading}>Web Site Settings</h1>
                    <label className={styles.my_label}>Messageboard:</label><br/>
                    <input className={styles.my_edit} type="text" value={messageboard} onChange={(e) => setMessageboard(e.target.value)}></input><br/>
                    <label className={styles.my_label}>PTY LTD:</label><br/>
                    <input className={styles.my_edit} type="text" value={company_name} onChange={(e) => setCompanyName(e.target.value)} required></input><br/>
                    <label className={styles.my_label}>Registration Number:</label><br/>
                    <input className={styles.my_edit} type="text" value={reg_no} onChange={(e) => setRegno(e.target.value)} required></input><br/>
                    <label className={styles.my_label}>VAT Number:</label><br/>
                    <input className={styles.my_edit} type="text" value={vat_no} onChange={(e) => setVatno(e.target.value)} required></input><br/>
                    <label className={styles.my_label}>Contact Details:</label><br/>
                    <input className={styles.my_edit} type="text" value={company_contacts} onChange={(e) => setContactno(e.target.value)} required></input><br/>
                    <label className={styles.my_label}>Company Email Address:</label><br/>
                    <input className={styles.my_edit} type="text" value={company_email} onChange={(e) => setEmail(e.target.value)} required></input><br/>
                    <label className={styles.my_label}>Address:</label><br/>
                    <input className={styles.my_edit} type="text" value={company_address} onChange={(e) => setAddress(e.target.value)} required></input><br/>
                    <label className={styles.my_label}>Site URL:</label><br/>
                    <input className={styles.my_edit} type="text" value={site_url} onChange={(e) => setUrl(e.target.value)} required></input><br/><br/>
                </div>

                <div className={styles.main}>
                    <h1 className={styles.myheading}>Misc Settings</h1>
                    <label className={styles.my_label}>Approve Email Address:</label><br/>
                    <input className={styles.my_edit} type="text" value={approver} onChange={(e) => setApprover(e.target.value)} required></input><br/>
                    <label className={styles.my_label}>Receive Jobs Email Address:</label><br/>
                    <input className={styles.my_edit} type="text" value={receive_jobs} onChange={(e) => setJobsReceive(e.target.value)} required></input><br/><br/>
                </div>
                <div className={styles.main}>
                    <input type="submit" name="btnOk" className={styles.btnOk} value="Update" />
                </div>
            </form>
        </div>
    )
};

export default Settings;

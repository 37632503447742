import React, { useState, useEffect } from "react";
import styles from './Inspection.module.scss';
import { post } from '../../api';
import { toast } from 'react-toastify';
import { useHistory } from "react-router-dom";
import  secureLocalStorage  from  "react-secure-storage";
import { saveInspectionForm, updateJobAndUser, updateSiteVisits } from '../../dexieDB';
import { setLocalStorageItem} from '../utils/Utils';


const Inspection = ({ formData, templateName, jobId, clientName, dateTime }) => {
  const [formElements, setFormElements] = useState([]);
  const [formValues, setFormValues] = useState({});
  const formDefinition = formData;
  const history = useHistory();
  const [readOnly, setReadOnly] = useState(false);
  const user_name = JSON.parse(secureLocalStorage.getItem('user')).username;
  const userId = JSON.parse(secureLocalStorage.getItem('user')).id;
  const [loading, setLoading] = useState(false);
  const isOnline = navigator.onLine;

  const currentDate = new Date();
  const newDate = currentDate.toISOString().split('T')[0];
  
  const handleInputChange = (element, value) => {
    setFormValues((prevValues) => ({
      ...prevValues,
      [element.label]: value,
    }));
  };

  const handleFileChange = (element, event) => {
    try {
      if (!event || !event.target || !event.target.files || event.target.files.length === 0) {
        throw new Error('No files selected.');
      }
  
      const file = event.target.files[0];
      
      if (!file) {
        throw new Error('File is undefined.');
      }
  
      const reader = new FileReader();
  
      reader.onload = (readerEvent) => {
        const image = new Image();
  
        image.onload = () => {
          const canvas = document.createElement('canvas');
          const ctx = canvas.getContext('2d');
  
          let newWidth = image.width;
          let newHeight = image.height;
          const aspectRatio = image.width / image.height;
  
          if (newWidth > 800) {
            newWidth = 800;
            newHeight = newWidth / aspectRatio;
          }
          if (newHeight > 800) {
            newHeight = 800;
            newWidth = newHeight * aspectRatio;
          }
  
          canvas.width = newWidth;
          canvas.height = newHeight;
  
          ctx.drawImage(image, 0, 0, newWidth, newHeight);  
          const base64String = canvas.toDataURL('image/jpeg', 0.8); // Set quality to 1 for maximum quality
  
          handleInputChange(element, base64String);
        };
  
        image.src = readerEvent.target.result;
      };
  
      reader.readAsDataURL(file);
    } catch (error) {
      console.error('Error handling file change:', error);
    }
  };
 
  const handleNext = async (e) => {
    e.preventDefault();
    setLoading(true);
    let errorOccurred = false;

    for (const element of formElements) {
        if (element.isRequired && (element.type === 'text' || element.isSelection === 'all' || element.isSelection === 'yesno' || element.isSelection === 'F' || element.isSelection === 'yes')) {
            const value = formValues[element.label];

            if (!value || value.trim() === '') {
                errorOccurred = true;
                toast.error(`Please fill in ${element.label}.`);
                setLoading(false); 
                return; 
            }
        }
    }

    if (errorOccurred) {
        return; 
    }

    if (isOnline) {
        try {
            const res = await post('/inspection_save', { formData, formValues, templateName, jobId });
            if (res.ok) {
                toast.success("Inspection has been saved successfully.", res.statusText);
                setReadOnly(true);
            } else {
                toast.error("Something went wrong with saving the inspection form. Please try again or contact the system administrator.");
            }
        } catch (error) {
            console.error("Error saving inspection form online:", error);
            toast.error("Something went wrong with saving the inspection form. Please try again or contact the system administrator.");
        } finally {
            setLoading(false); 
        }
    } else {
        try {
            const initialFormData = {};
            const updatedFields = formDefinition.fields.map(field => {
                if (formValues[field.label]) {
                    initialFormData[field.label] = formValues[field.label];
                    return { ...field, value: formValues[field.label] };
                } else {
                    initialFormData[field.label] = '';
                    return field;
                }
            });
            await saveInspectionForm({ ...formDefinition, fields: updatedFields }, templateName, jobId);
            toast.success("Inspection has been saved offline. It will be synchronized when online.");
            setReadOnly(true);
        } catch (error) {
            console.error("Error saving inspection form offline:", error);
            toast.error("Something went wrong with saving the inspection form offline. Please try again later.");
        } finally {
            setLoading(false);
        }
    }
  };


  const handleBack = async() => {
    history.push({
      pathname: '/clients', 
      state: 
        { 
          jobId: jobId
      }
    })
  };

  const GoToInspectSignature = async (e) => {
    e.preventDefault();
    setLocalStorageItem('active_site_visit', 0);
    if (isOnline) {
      const res = await post('/jobs_update', { jobId, JobStatus: 2, userId });
      if (res.ok) {
        history.push({
          pathname: '/client_signature',
          state: {
            jobId: jobId,
            templateName: templateName
          }
        });
      } else {
        toast.error("Unable to end job time. Please try again or contact system administrator.");
      }
    } else {
      await updateJobAndUser({ jobId: jobId, JobStatus: 2, userId: userId });
      await updateSiteVisits({ jobid: jobId });
      toast.success("Job status saved locally. Will sync when back online.");
      history.push({
        pathname: '/client_signature',
        state: {
          jobId: jobId,
          templateName: templateName
        }
      });
    }
  };

  useEffect(() => {
    const show_forms = async () => {
      const resp = formData;

      if (resp) {
        setFormElements(formDefinition.fields);
      }
    };

    show_forms();
  }, [formData, formDefinition.fields]);

  return (
    <>
    <div className={styles.main}>
      {readOnly ? 
        <h3>Plase confirm all details</h3>
        : null
      }
      <h3>{formData.displayName}</h3>
      <form>
        {formElements.map((element, index) => (
          <div key={index}>
            {element.isHeader || element.isHeaderOnly || !element.isSelection === '' ? (
              <h3
                className={styles.jobs_section}
                style={{
                  textAlign: element.isSpecialField ? 'left' : 'center',
                  backgroundColor: element.bgColor,
                }}
              >
                {element.label}
              </h3>
            ) : (
              element.type !== 'button' && (
                <label className={`jobslabel ${element.isSpecialField ? 'special' : ''}`} required={element.isRequired}>
                  {element.label}:
                </label>
              )
            )}
            <div className="textbox-selection-container">
            {element.type === 'text' && (element.label === 'Client') && (
              <input className={styles.jobsreadonlyedit} type="text" placeholder={element.label} style={{ textAlign: 'left' }} 
                required={element.isRequired} value={clientName} disabled={readOnly} />
            )}
            {element.type === 'text' && (element.label === 'Inspector') && (
              <input className={styles.jobsreadonlyedit} type="text" placeholder={element.label} style={{ textAlign: 'left' }} 
                required={element.isRequired} value={user_name} disabled={readOnly} />
            )}
            {element.type === 'text' && (element.label === 'Start Time') && (
              <input className={styles.jobsreadonlyedit} type="text" placeholder={element.label} style={{ textAlign: 'left' }} 
                required={element.isRequired} value={dateTime} disabled={readOnly} />
            )}
            {element.type === 'text' && (!element.isHeaderOnly && element.label !== 'Client') && (element.label !== 'Start Time') && (element.label !== 'Inspector') && (
              <input className={styles.jobs_check_value} type="text" placeholder={element.label} style={{ textAlign: 'left' }} 
                required={element.isRequired} onChange={(e) => handleInputChange(element, e.target.value)} disabled={readOnly} />
            )}
            {element.type === 'text' && (element.isExtraValueType) && (
              <>
               <select className={styles.jobs_check} disabled={true} defaultValue={element.isExtraValueType}>
                <option value=""></option>
                <option value="kPa">kPa</option>
                <option value="A">A</option>
                <option value="V">V</option>
                <option value="L/min">L/min</option>
                <option value="kW">kW</option>
                <option value="rmp">rmp</option>
                <option value="ml">ml</option>
                <option value="sec">sec</option>
                <option value="rpm">rpm</option>
                <option value="°C">°C</option>
                <option value="L/s">L/s</option>
                <option value="Hrs">Hrs</option>
                <option value="F">F</option>
              </select>
              </>
            )}
            </div>
            {element.type === 'number' && (
              <input className="jobsreadonlyedit" type="number" placeholder={element.label} 
                required={element.isRequired} onChange={(e) => handleInputChange(element, e.target.value)} disabled={readOnly} />
            )}
            {element.type === 'email' && (
              <input className="jobsreadonlyedit" type="email" placeholder={element.label} 
                required={element.isRequired} onChange={(e) => handleInputChange(element, e.target.value)} disabled={readOnly} />
            )}
            {element.type === 'date' && (
              <input className="jobsreadonlyedit" type="date" placeholder={element.label} 
                required={element.isRequired} onChange={(e) => handleInputChange(element, e.target.value)} disabled={readOnly} defaultValue={newDate} />
            )}
            {element.type === 'checkbox' && (
              <input className="jobsreadonlyedit" type="checkbox" placeholder={element.label} 
                required={element.isRequired} onChange={(e) => handleInputChange(element, e.target.value)} disabled={readOnly} />
            )}
            {element.type === 'file' && <input type="file" placeholder={element.label} 
              required={element.isRequired} onChange={(e) => handleFileChange(element, e)} disabled={readOnly} />}
            {element.type === 'textarea' && (
              <textarea className="jobs_notes" placeholder={element.label} required={element.isRequired} 
                rows={4} onChange={(e) => handleInputChange(element, e.target.value)} disabled={readOnly} />
            )}
            {loading && (
              <div className={styles.loaderContainer}>
                  <div className={styles.loader}></div>
                  <span>Loading...</span>
              </div>
            )}
            {!readOnly ?
              element.type === 'button' && element.label === 'Next' && (
                <input type="submit" onClick={handleNext} className="submitBtn" value={element.label} required={element.isRequired} />
              )
            :
              element.type === 'button' && element.label === 'Next' && (
                <input type="submit" onClick={GoToInspectSignature} className="submitBtn" value={element.label} required={element.isRequired} />
            )}
            {readOnly ?
              element.type === 'button' && element.label === 'Re-edit Details' && (
                <input type="submit" onClick={() => setReadOnly(false)} className="submitBtn" value={element.label} required={element.isRequired} />
              )
            :
              null
            }
            {!readOnly ?
              element.type === 'button' && element.label === 'Back To Clients' && (
                <input type="submit" onClick={handleBack} className="submitBtn" value={element.label} required={element.isRequired} />
            )
            :
              null
            }
            {element.isSelection === 'all' && (
              <select className={styles.jobs_check} onChange={(e) => handleInputChange(element, e.target.value)} disabled={readOnly} required={element.isRequired}>
                <option value=""></option>
                <option value="yes">Yes</option>
                <option value="no">No</option>
                <option value="na">N/A</option>
              </select>
            )}
            {element.isSelection === 'no' && (
              <select className={styles.jobs_check} onChange={(e) => handleInputChange(element, e.target.value)} disabled={readOnly} required={element.isRequired}>
                <option value=""></option>
                <option value="no">No</option>
              </select>
            )}
            {element.isSelection === 'yes' && (
              <select className={styles.jobs_check} onChange={(e) => handleInputChange(element, e.target.value)} disabled={readOnly} required={element.isRequired}>
                <option value=""></option>
                <option value="yes">Yes</option>
              </select>
            )}
            {element.isSelection === 'yesno' && (
              <select className={styles.jobs_check} onChange={(e) => handleInputChange(element, e.target.value)} disabled={readOnly} required={element.isRequired}>
                <option value=""></option>
                <option value="yes">Yes</option>
                <option value="no">No</option>
              </select>
            )}
            {element.isSelection === 'F' && (
              <select className={styles.jobs_check} onChange={(e) => handleInputChange(element, e.target.value)} disabled={readOnly} required={element.isRequired}>
                <option value=""></option>
                <option value="F">F</option>
                <option value="¾">¾</option>
                <option value="½">½</option>
                <option value="¼">¼</option>
                <option value="E">E</option>
              </select>
            )}
            </div>
        ))}
      </form>
    </div>
    </>
  )
};

export default Inspection;
import React, { useState, useEffect, useRef } from "react";
import styles from './Inspection.module.scss';
import { useHistory } from "react-router-dom";
import SignaturePad from 'signature_pad';
import  secureLocalStorage  from  "react-secure-storage";
import { toast } from 'react-toastify';
import { post } from "../../api";
import { updateInspectSignature } from '../../dexieDB';

const InspectionSignature = () => {
    const canvasRef = useRef(null);
    const signaturePadRef = useRef(null);
    const history = useHistory();
    const templateName = history.location.state.templateName;
    const [signatureName, setSignatureName] = useState('');
    const jobId = history.location.state.jobId;
    const isOnline = navigator.onLine;

    useEffect(() => {
        const canvas = canvasRef.current;
        const signaturePad = new SignaturePad(canvas);

        signaturePadRef.current = signaturePad;
        signaturePad.minWidth = 1;
        signaturePad.maxWidth = 3;
    
        return () => {
          signaturePad.clear();
        };
      }, []);
    
    const handleClear = () => {
        const signaturePad = new SignaturePad(canvasRef.current);
        signaturePad.clear();
    };

    const GoToClientSignature = () => {
        history.push({
            pathname: '/client_signature', 
            state: 
              { 
                jobId: jobId,
                templateName: templateName
            }
        })
    };

    const saveSignature = async () => {
        if (signaturePadRef.current) {
          const signatureData = signaturePadRef.current.toDataURL();
      
          if (signaturePadRef.current.isEmpty()) {
            toast.error("No inspector signature has been drawn");
          } else {
            toast.dismiss();
      
            if (isOnline) {
              const res = await post('/inspection_save_signature', {
                inspectSignature: signatureData.split(',')[1],
                jobId,
                signatureName
              });
      
              if (res.ok) {
                history.push({
                  pathname: '/client_signature',
                  state: {
                    jobId: jobId,
                    templateName: templateName
                  }
                });
                toast.success("Inspector signature saved successfully");
              } else {
                toast.error("Unable to save inspector signature");
                handleClear();
              }
      
              return res.ok;
            } else {
              await updateInspectSignature({
                jobId,
                inspectSignature: signatureData.split(',')[1]
              });
              toast.success("Inspector signature saved locally. Will sync when back online.");
              history.push({
                pathname: '/client_signature',
                state: {
                  jobId: jobId,
                  templateName: templateName
                }
              });
              return true;
            }
          }
        }
      };
   
 
    return (
        <>
        <div className={styles.main}>
            <div className={styles.form}>
                <p>I hereby confirm the completion of the {templateName}</p>
                <p>Inspector Name</p>
                <input type="text" className={styles.client_input} value={JSON.parse(secureLocalStorage.getItem('user')).username} />
                <br />
                <p>Inspector Signature</p>
                <div className={styles.signature_container}>
                <button className={styles.signature_clear} onClick={handleClear}>Clear</button>
                    <canvas className={styles.signature_canvas} ref={canvasRef} width={400} height={200}></canvas>
                    <div className={styles.signature_label_container}>
                        <p>Please sign above</p>
                    </div>
                </div>
                <br />
                <br />
                <div className={styles.btn_container}>
                    <button className={styles.btn_signature} onClick={() => saveSignature()}>Next</button>
                    <button className={styles.btn_signature} onClick={() => GoToClientSignature()}>Back</button>
                </div>
            </div>
        </div>
        </>
    )
};

export default InspectionSignature;
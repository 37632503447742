import React, { useEffect, useState } from 'react';
import styles from './InspectionCategory.module.scss';
import { post } from '../../api';
import { toast } from 'react-toastify';
import { useHistory } from "react-router-dom";

const InspectionCatNew = () => {
    const [category_name, setCategoryName] = useState('');
    const history = useHistory();

    const submit = async (event) => {
        event.preventDefault();
        const res = await post('/inspection_cat_new', { category_name });
        if (res.ok)
        {
            toast.success("New category has been saved.", res.statusText);
            history.push('/inspection_category');
        }
        else if (res.status === 404)
        {
            toast.error("Inspection category already exist.");
        }
        else
        {
            toast.error("Something went wrong with saving the settings. Please contact system administrator.");
        }
        return res.ok;
    };
    return (
        <div>
            <form method="post" onSubmit={submit}>
                <div className={styles.main}>
                    <h1 className={styles.myheading}>New Inspection Category</h1>
                    <label className={styles.my_label}>Category Name:</label><br/>
                    <input className={styles.my_edit} type="text" value={category_name} onChange={(e) => setCategoryName(e.target.value)}/><br/>
                </div>
                <div className={styles.main}>
                    <input type="submit" className={styles.btnOk} value="Save"/>
                </div>
            </form>
        </div>
    )
};

export default InspectionCatNew;
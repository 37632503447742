import React, { useState, useEffect } from 'react';
import styles from './OfflineSyncData.module.scss';
import db, { syncJobsInfo, syncInspectionCategoryInfo, syncFormsInfo, syncInspectionsInfo, saveClientInfo } from '../../dexieDB';
import { get, post } from '../../api';
import { useUser } from '../../context';
import secureLocalStorage from 'react-secure-storage';


const OfflineSyncData = () => {
    const [countClient, setClientCount] = useState(0);
    const [countInspectionCat, setInspectionCatCount] = useState(0);
    const [countJobs, setJobsCount] = useState(0);
    const [countInspections, setInspectionsCount] = useState(0);
    const [countForms, setFormsCount] = useState(0);
    const [countConfig, setConfigCount] = useState(0);
    const [error, setError] = useState('');
    const [jobs, setJobs] = useState({});
    const [inspectionCategory, setInspectionCategory] = useState({});
    const [forms, setForms] = useState({});
    const [inspections, setInspections] = useState({});
    const [clients, setClients] = useState({});
    const { user, setUser } = useUser([]);
    const [loading, setLoading] = useState(false);


    const countEntriesPerTable = async () => {
        try {
          await db.open();
      
          const inspectionsCount = await db.inspection.count();
          setInspectionsCount(inspectionsCount);
          const clientsCount = await db.clients.count();
          setClientCount(clientsCount);
          const jobsCount = await db.jobs.count();
          setJobsCount(jobsCount);
          const formsCount = await db.forms.count();
          setFormsCount(formsCount);
          const configCount = await db.config.count();
          setConfigCount(configCount);
          const inspectionCatCount = await db.inspection_category.count();
          setInspectionCatCount(inspectionCatCount);
        } catch (error) {
          setError("Error", error)
          console.error("Error counting entries in tables:", error);
        }
      };

    useEffect(() => {
        const fetchOfflineUser = async () => {
            const storedUser = secureLocalStorage.getItem('user');
            if (storedUser) {
                setUser(JSON.parse(storedUser));
            }
        };

        fetchOfflineUser();
    }, [setUser]);
    
    useEffect(() => {
        countEntriesPerTable();
        const intervalId = setInterval(countEntriesPerTable, 5000);
        return () => clearInterval(intervalId);
    }, []);

    const getJobs = async () => {
        setLoading(true);
        const resp = await post(`/jobs_list`, { userid: user.id, filterlist: '' });
        try {
            if (resp.ok) {
            let jobs = await resp.json(); 
            setJobs(jobs);
            syncJobsInfo(jobs);
            setLoading(false);
            } else{
                setLoading(false);
            }
        } catch (err) {
            setError(err.message);
            setLoading(false);
        }
    };

    const getInspectionCategory = async () => {
        setLoading(true);
        const resp = await get('/inspection_category_list');
        try {
            if (resp.ok) {
            let jobs = await resp.json(); 
            setInspectionCategory(jobs);
            syncInspectionCategoryInfo(jobs);
            setLoading(false);
            } else{
                setLoading(false);
            }
        } catch (err) {
            setError(err.message);
            setLoading(false);
        } 
    };

    const getForms = async () => {
        setLoading(true);
        const resp = await get('/forms_list');

        try {
            if (resp.ok) {
            let forms = await resp.json(); 
            console.log("check forms", forms);
            setForms(forms);
            syncFormsInfo(forms);
            } else{
                setLoading(false);
            }
        } catch (err) {
            setError(err.message);
            setLoading(false);
        } 
    };

    const getInspections = async () => {
        setLoading(true);
        const resp = await get('/inspections');
        try {
            if (resp.ok) {
            let inspections = await resp.json(); 
            console.log("check inspections", inspections);
            setInspections(inspections);
            syncInspectionsInfo(inspections);
            } else{
                setLoading(false);
            }
        } catch (err) {
            setError(err.message);
            setLoading(false);
        }
    };

    const getClients = async () => {
        setLoading(true);
        const resp = await get(`/client_list`);
        try {    
            if (resp.ok) {
                let clients = await resp.json()
                setClients(clients);
                saveClientInfo(clients);
            } else{
                setLoading(false);
            }
        } catch (err) {
            setError(err.message);
            setLoading(false);
        }
    };

    return (
        <div>
            {loading && (
            <div className={styles.loaderContainer}>
                <div className={styles.loader}></div>
                <span>Loading...</span>
            </div>
            )}
            <div className={styles.main}>
                <h1 className={styles.myheading}>Offline Sync Data</h1>
                <div className={styles.card}>
                    <label>Clients: {countClient}</label>
                    <button className={styles.syncBtn} onClick={() => getClients()}>Sync Data</button>
                </div>
                <div className={styles.card}>
                    <label>Inspection Category: {countInspectionCat}</label>
                    <button className={styles.syncBtn} onClick={() => getInspectionCategory()}>Sync Data</button>
                </div>
                <div className={styles.card}>
                    <label>Jobs: {countJobs}</label>
                    <button className={styles.syncBtn} onClick={() => getJobs()}>Sync Data</button>
                </div>
                <div className={styles.card}>
                    <label>Inspections: {countInspections}</label>
                    <button className={styles.syncBtn} onClick={() => getInspections()}>Sync Data</button>
                </div>
                <div className={styles.card}>
                    <label>Forms: {countForms}</label>
                    <button className={styles.syncBtn} onClick={() => getForms()}>Sync Data</button>
                </div>
                <div className={styles.card}>
                    <label>Config: {countConfig}</label>
                    <button className={styles.syncBtn}>Sync Data</button>
                </div>
            </div>
            {error ?
                <div className={styles.main}>
                    <h1 className={styles.myheading}>Error Messages</h1>
                    <label>Messages: {error}</label>
                </div>
            : null
            }
        </div>
    )
};

export default OfflineSyncData;
import React from 'react';
import { Link } from 'react-router-dom';

import styles from './NotFound.module.scss';

const NotFound = () => {
  return (
    <div className={styles.NotFound}>
      <span>The page you are looking for can't be found.</span>
      <span>Would you like to go back to the home page?</span>
      <Link to="/">Take me to the home page</Link>
    </div>
  );
};

export default NotFound;

import React, { useState, useEffect } from "react";
import './App.css';
import { get, post } from './api';

import {
  Checkbox,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  MenuItem,
  Select
} from "@mui/material";
import { useDrag, useDrop } from 'react-dnd';

import { Offline, Online } from 'react-detect-offline';
import { ChromePicker } from 'react-color';
import { toast } from 'react-toastify';


function FormBuilder() {
  const [formElements, setFormElements] = useState([]);
  const [open, setOpen] = useState(false);
  const [forms, setForms] = useState([]);
  const [selectedTemplate, setSelectedTemplate] = useState('');
  const [newField, setNewField] = useState({ label: "", type: "selection", isHeader: false, isHeaderOnly: false, isSelection: '', additionalValues: '', isRequired: false });
  const [checked, setChecked] = useState(false);
  const [headerOnlyChecked, setHeaderOnlyChecked] = useState(false);
  const [displayColorPicker, setDisplayColorPicker] = useState(false);
  const [formName, setFormName] = useState('');
  const [displayName, setDisplayName] = useState('');
  const [isNewForm, setIsNewForm] = useState(true);
  const [isFormLoaded, setFormLoaded] = useState(false);
  const [inspectionCategory, setInspectionCategory] = useState([]);
  const [selectedInspectionCategory, setSelectedInspectionCategory] = useState('');
  const [updateInspectionCategory, setUpdateInspectionCategory] = useState('');
  const [selectedOption, setSelectedOption] = useState('');
  const [extraValue, setExtraValue] = useState(false);
  const [extraValuesRange, setExtraValuesRange] = useState(1);
  const [extraValues, setExtraValues] = useState(Array.from({ length: extraValuesRange }, () => ''));
  const [extraValuesType, setExtraValuesType] = useState(Array.from({ length: extraValuesRange }, () => ''));
  
  const handleSelectionChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const handleExtraValueChange = (index, value) => {
    const updatedValues = [...extraValues];
    updatedValues[index] = value;
    setExtraValues(updatedValues);
  };

  const handleExtraValueTypeChange = (index, value) => {
    const updatedValues = [...extraValuesType];
    updatedValues[index] = value;
    setExtraValuesType(updatedValues);
  };

  useEffect(() => {
    const isFormNew = !selectedTemplate && !isFormLoaded;

    setIsNewForm(isFormNew);
  }, [selectedTemplate, formName, isFormLoaded]);

  const moveElement = (fromIndex, toIndex) => {
    const updatedFormElements = [...formElements];
    const [movedElement] = updatedFormElements.splice(fromIndex, 1);
    updatedFormElements.splice(toIndex, 0, movedElement);
    setFormElements(updatedFormElements);
  };

  const handleRemoveElement = (index) => {
    const updatedFormElements = [...formElements];
    updatedFormElements.splice(index, 1);
    setFormElements(updatedFormElements);
  };
  
  const DraggableFormElement = ({ element, index, moveElement, removeElement }) => {
    const [{ isDragging }, drag] = useDrag({
      type: 'FORM_ELEMENT',
      item: { index },
      collect: (monitor) => ({
        isDragging: !!monitor.isDragging(),
      }),
    });
  
    const [, drop] = useDrop({
      accept: 'FORM_ELEMENT',
      hover: (draggedItem) => {
        if (draggedItem.index !== index) {
          moveElement(draggedItem.index, index);
          draggedItem.index = index;
        }
      },
    });

    const handleRemoveClick = () => {
      removeElement(index);
    };
  
    return (
      <div ref={(node) => drag(drop(node))} style={{ opacity: isDragging ? 0.5 : 1 }}>
        <div>
          {element.isHeader || element.isHeaderOnly || !element.isSelection === '' ? (
            <h3
              className="jobshead"
              style={{
                textAlign: element.isSpecialField ? 'left' : 'center',
                backgroundColor: element.bgColor,
              }}
            >
              {element.label}
            </h3>
          ) : (
            element.type !== 'button' && (
              <label className={`jobslabel ${element.isSpecialField ? 'special' : ''}`} required={element.isRequired}>
                {element.label}:
              </label>
            )
          )}
          {element.type === 'text' && (!element.isHeaderOnly || selectedOption === '99') && (
            <input className="jobsreadonlyedit" type="text" placeholder={element.label} style={{ textAlign: 'left' }} required={element.isRequired} />
          )}
          {element.type === 'number' && (
            <input className="jobsreadonlyedit" type="number" placeholder={element.label} required={element.isRequired} />
          )}
          {element.type === 'email' && (
            <input className="jobsreadonlyedit" type="email" placeholder={element.label} required={element.isRequired} />
          )}
          {element.type === 'date' && (
            <input className="jobsreadonlyedit" type="date" placeholder={element.label} required={element.isRequired} />
          )}
          {element.type === 'checkbox' && (
            <input className="jobsreadonlyedit" type="checkbox" placeholder={element.label} required={element.isRequired} />
          )}
          {element.type === 'file' && <input type="file" placeholder={element.label} required={element.isRequired} />}
          {element.type === 'textarea' && (
            <textarea className="jobs_notes" placeholder={element.label} required={element.isRequired} rows={4} />
          )}
          {element.type === 'button' && (
            <input type="submit" className="submitBtn" value={element.label} required={element.isRequired} />
          )}
          {element.isSelection === 'all' && (
            <select className="formSelect">
              <option value=""></option>
              <option value="yes">Yes</option>
              <option value="no">No</option>
              <option value="na">N/A</option>
              <option value="99"></option>
            </select>
          )}
          {element.isSelection === 'no' && (
            <select className="formSelect">
              <option value=""></option>
              <option value="no">No</option>
            </select>
          )}
          {element.isSelection === 'yes' && (
            <select className="formSelect">
              <option value=""></option>
              <option value="yes">Yes</option>
            </select>
          )}
          {element.isSelection === 'yesno' && (
            <select className="formSelect">
              <option value=""></option>
              <option value="yes">Yes</option>
              <option value="no">No</option>
            </select>
          )}
          {element.isSelection === 'F' && (
            <select className="formSelect">
              <option value=""></option>
              <option value="F">F</option>
              <option value="¾">¾</option>
              <option value="½">½</option>
              <option value="¼">¼</option>
              <option value="E">E</option>
            </select>
          )}
          <button className="removeElementsBtn" onClick={handleRemoveClick}>Remove</button>
        </div>
      </div>
    );
  };
  
  const handleCheckboxClick = () => {
    setChecked(!checked);
  };

  const handleHeaderOnlyCheck = () => {
    setHeaderOnlyChecked(!headerOnlyChecked);
  };

  const handleExtraValueCheck = () => {
    setExtraValue(!extraValue);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleColorChange = (color) => {
    setNewField({ ...newField, bgColor: color.hex });
  };

  const toggleColorPicker = () => {
    setDisplayColorPicker(!displayColorPicker);
  };

  const loadForm = async() => {
    const res = await post(`/forms_single`, { jobtype: selectedTemplate });

    if (selectedTemplate === '' || selectedTemplate === undefined) {
      setFormElements([]);
    }

    if (res.ok) {
      const json = await res.json();

      json.map((item) => {
        const jsonData = JSON.parse(item.form_data);
        setFormElements(jsonData.fields);
        setFormLoaded(true);
        setDisplayName(jsonData.displayName);
        setFormName(jsonData.name);
        setUpdateInspectionCategory(jsonData.inspectionCategory);
        setIsNewForm(false);
    });
    }
    else {
      toast.error("Unable to load form", res.status);
    }
  };

  const saveForm = async (event) => {
    const basicFields = [
      {
        label: "Client",
        type: "text",
        isHeader: true,
        isHeaderOnly: false,
        isSpecialField: true,
        isSelection: '',
        additionalValues: ''
      },
      {
        label: "Inspector",
        type: "text",
        isHeader: true,
        isHeaderOnly: false,
        bgColor: "#ffffff",
        isSpecialField: true,
        isSelection: '',
        additionalValues: ''
      },
      {
        label: "Start Time",
        type: "text",
        isHeader: true,
        isHeaderOnly: false,
        bgColor: "#ffffff",
        isSpecialField: true,
        isSelection: '',
        additionalValues: ''
      },
    ];

    const allFields = [...basicFields, ...formElements];
    allFields.push({
      label: "Next",
      type: "button",
      isHeader: false,
      bgColor: "#ffffff",
      isRequired: false,
      selectedMenuItem: "button",
      isSpecialField: false,
      colname: "col99999",
    },
    {
      label: "Re-edit Details",
      type: "button",
      isHeader: false,
      bgColor: "#ffffff",
      isRequired: false,
      selectedMenuItem: "button",
      isSpecialField: false,
      colname: "col99999",
    },
    {
      label: "Back To Clients",
      type: "button",
      isHeader: false,
      bgColor: "#ffffff",
      isRequired: false,
      selectedMenuItem: "button",
      isSpecialField: false,
      colname: "col99999",
    }
    );
    const allFieldsWithColname = allFields.map((field, index) => ({ ...field, colname: `col${index + 1}` }));

    const formDefinition = { name: formName, displayName: displayName, 
      inspectionCategory: selectedInspectionCategory ? selectedInspectionCategory : updateInspectionCategory, fields: allFieldsWithColname 
    };
    const jsonData = JSON.stringify(formDefinition);

    event.preventDefault();

    const res = await post('/forms_save', { formName, jsonData });
    
    if (res.ok) {
      const json = await res.json();
      toast.success("Form has been saved successfully", res.statusText);
      return json;
    }
    else {
     toast.error("Unable to save form", res.statusText);
    }
    return res.ok;
  };

  const updateForm = async (event) => {
    const basicFields = [
      {
        label: "Client",
        type: "text",
        isHeader: true,
        isSpecialField: true,
      },
      {
        label: "Inspector",
        type: "text",
        isHeader: true,
        bgColor: "#ffffff",
        isSpecialField: true,
      },
      {
        label: "Start Time",
        type: "text",
        isHeader: true,
        bgColor: "#ffffff",
        isSpecialField: true,
      },
    ];

    const allFields = isNewForm ? [...basicFields, ...formElements] : formElements;
    if (isNewForm) {
      allFields.push({
        label: "Next",
        type: "button",
        isHeader: false,
        bgColor: "#ffffff",
        isRequired: false,
        selectedMenuItem: "button",
        isSpecialField: false,
        colname: "col99999",
      },
      {
        label: "Re-edit Details",
        type: "button",
        isHeader: false,
        bgColor: "#ffffff",
        isRequired: false,
        selectedMenuItem: "button",
        isSpecialField: false,
        colname: "col99999",
      },
      {
        label: "Back To Clients",
        type: "button",
        isHeader: false,
        bgColor: "#ffffff",
        isRequired: false,
        selectedMenuItem: "button",
        isSpecialField: false,
        colname: "col99999",
      });
    };
    const allFieldsWithColname = allFields.map((field, index) => ({ ...field, colname: `col${index + 1}` }));

    const formDefinition = { name: selectedTemplate ? selectedTemplate : formName, displayName: displayName, 
      inspectionCategory: selectedInspectionCategory ? selectedInspectionCategory : updateInspectionCategory, fields: allFieldsWithColname
    };
    const jsonData = JSON.stringify(formDefinition);

    event.preventDefault();

    const res = await post('/forms_update', { formName, jsonData });
    
    if (res.ok) {
      const resp = await res.text();

      if (resp === "Success") {
        toast.success("Form has been updated", res.statusText);
      }
    }
    else {
     toast.error("Unable to update form", res.statusText);
    }
    return res.ok;
  };

  const saveFormSchema = () => {
    const basicFields = [
      {
        label: "Client",
        type: "text",
        isHeader: true,
        isHeaderOnly: false,
        isSpecialField: true,
        isSelection: '',
        additionalValues: ''
      },
      {
        label: "Inspector",
        type: "text",
        isHeader: true,
        isHeaderOnly: false,
        bgColor: "#ffffff",
        isSpecialField: true,
        isSelection: '',
        additionalValues: ''
      },
      {
        label: "Start Time",
        type: "text",
        isHeader: true,
        isHeaderOnly: false,
        bgColor: "#ffffff",
        isSpecialField: true,
        isSelection: '',
        additionalValues: ''
      },
    ];

    const allFields = isNewForm ? [...basicFields, ...formElements] : formElements;
    
    if (isNewForm) {
      allFields.push({
        label: "Next",
        type: "button",
        isHeader: false,
        bgColor: "#ffffff",
        isRequired: false,
        selectedMenuItem: "button",
        isSpecialField: false,
        colname: "col99999",
      },
      {
        label: "Re-edit Details",
        type: "button",
        isHeader: false,
        bgColor: "#ffffff",
        isRequired: false,
        selectedMenuItem: "button",
        isSpecialField: false,
        colname: "col99999",
      },
      {
        label: "Back To Clients",
        type: "button",
        isHeader: false,
        bgColor: "#ffffff",
        isRequired: false,
        selectedMenuItem: "button",
        isSpecialField: false,
        colname: "col99999",
      });
    };
    const allFieldsWithColname = allFields.map((field, index) => ({ ...field, colname: `col${index + 1}` }));
    const formDefinition = { name: selectedTemplate ? selectedTemplate : formName, displayName: displayName, fields: allFieldsWithColname };
    const jsonData = JSON.stringify(formDefinition);
    const blob = new Blob([jsonData], { type: "application/json" });
    const url = URL.createObjectURL(blob);

    const a = document.createElement("a");
    a.href = url;
    a.download = `${selectedTemplate ? selectedTemplate : formName}.json`;
    a.click();
    URL.revokeObjectURL(url);
  };
  
  const addField = () => {
    const isHeader = checked;
    const isHeaderOnly = headerOnlyChecked;
    const isRequired = newField.isRequired;
    const isSelection = selectedOption;
    const additionalValues = extraValues;
    const field = { ...newField, isHeader, isHeaderOnly, isSelection, additionalValues, isRequired };
  
    if (newField.label === "Client" || newField.label === "Inspector" || newField.label === "Start Time") {
      field.isSpecialField = true;
    } else {
      field.isSpecialField = false;
    }
    let updatedFormElements = [...formElements, field];

    if (extraValue && extraValues.length > 0) {
      extraValues.forEach((extraValue, index) => {
        const extraField = {
          label: extraValues[index],
          type: "text", 
          isHeader: false,
          isHeaderOnly: false,
          isSelection: '',
          bgColor: "#ffffff",
          isRequired: false,
          isSpecialField: true,
          isExtraValueType: extraValuesType[index],
          colname: `col${formElements.length + index + 1}`,
          value: extraValue,
        };
  
        updatedFormElements = [...updatedFormElements, extraField];
      });
    }
    
    setFormElements(updatedFormElements);
    setNewField({ label: "", type: "text", isHeader: false, isHeaderOnly: false, isSelection: '', bgColor: "#ffffff", isRequired: false });
    handleClose();
  };

  useEffect(() => {
    const get_forms = async () => {
      const resp = await get(`/forms_list`);

      if (resp.ok) {
        setForms(await resp.json());
      }
    };

    get_forms();
  }, []);

  useEffect(() => {
    const get_inspection_category = async () => {
      const resp = await get(`/inspection_category_list`);
      if (resp.ok) {
        setInspectionCategory(await resp.json());
      }
    };
    get_inspection_category();
  }, []);

  return (
    <div className="container">
       <div className="sidebar">
          <label className="templateHeader">Import / Export Functions</label>
          <hr className="horizontal-line" />
          <br />
          <label className="templateHeader">Import template</label>
          <input
            className="templateInput"
            type="file"
            accept=".json"
            onChange={(e) => {
              const file = e.target.files[0];
              const reader = new FileReader();

              reader.onload = (e) => {
                const jsonData = e.target.result;
                const formDefinition = JSON.parse(jsonData);
                setFormElements(formDefinition.fields);
                setDisplayName(formDefinition.displayName);
                setFormName(formDefinition.name);
                setIsNewForm(false);
                setFormLoaded(true);
                setUpdateInspectionCategory(jsonData.inspectionCategory);
              };

              reader.readAsText(file);
            }}
          />
          <br />
          <Button variant="contained" color="primary" onClick={saveFormSchema}>
            Export Template
          </Button>
          <br />
          <br />
          <label className="templateHeader">Form Functions</label>
          <hr className="horizontal-line" />
          <br />
          <div className="sidebarBtn">
            <Button variant="contained" color="primary" onClick={handleOpen}>
              Add Field
            </Button>
            <Online>
              <>
                {isFormLoaded ?
                <Button variant="contained" color="primary" onClick={updateForm}>
                    Update Form
                </Button>
                : 
                <Button variant="contained" color="primary" onClick={saveForm}>
                  Save Form
                </Button>
                }
              </>
            </Online>
          </div>
          <br />
          <label className="templateHeader">Form Templates</label>
          <select className="templateList"
            value={selectedTemplate}
            onChange={(e) => setSelectedTemplate(e.target.value)}>
            <option value=""></option>
            {forms.map((item) => (
              <option key={item.form_name} value={item.form_name}>
                {item.form_name}
              </option>
            ))}
          </select>
          <br />
          <Button variant="contained" color="primary" onClick={loadForm}>
            Load Form
          </Button>
        </div>
       <div className="content">
      <h2>Form Builder</h2>
        <form>
          {formElements.map((element, index) => (
              <DraggableFormElement
              element={element}
              index={index}
              moveElement={moveElement}
              removeElement={(index) => handleRemoveElement(index)}
              key={index}
            />
          ))}
        </form>
      </div>

      <Dialog open={open} onClose={handleClose}>
        <select className="InspectionCat"
          value={selectedInspectionCategory}
          onChange={(e) => setSelectedInspectionCategory(e.target.value)}>
          <option value=""></option>
          {inspectionCategory.map((item) => (
            <option key={item.category} value={item.category}>
              {item.category}
            </option>
          ))}
        </select>
        <TextField
          label="Form Name"
          value={formName}
          onChange={(e) => setFormName(e.target.value)}
        />
        <TextField
          label="Display Name"
          value={displayName}
          onChange={(e) => setDisplayName(e.target.value)}
        />
        <DialogTitle>Add Field</DialogTitle>
        <DialogContent className="fieldType">
          <DialogContentText>
            Enter the field name and type.
          </DialogContentText>
          <TextField
            label="Field Label"
            value={newField.label}
            onChange={(e) => setNewField({ ...newField, label: e.target.value })}
          />
          <TextField
            select
            label="Field Type"
            value={newField.type}
            onChange={(e) => setNewField({ ...newField, type: e.target.value, selectedMenuItem: e.target.value })}
          >
            <MenuItem value="selection">Selection</MenuItem>
            <MenuItem value="text">Text</MenuItem>
            <MenuItem value="number">Number</MenuItem>
            {/* <MenuItem value="email">Email</MenuItem> */}
            <MenuItem value="radio">Radio</MenuItem>
            <MenuItem value="date">Date</MenuItem>
            <MenuItem value="checkbox">Checkbox</MenuItem>
            <MenuItem value="file">File</MenuItem>
            <MenuItem value="textarea">Textarea</MenuItem>
          </TextField>
        </DialogContent>
        {headerOnlyChecked ?
        <DialogContentText>
          <Checkbox checked={checked} disabled={true}
           onClick={handleCheckboxClick} />
            Is Header
        </DialogContentText>
        :
        <DialogContentText>
          <Checkbox checked={checked}
           onClick={handleCheckboxClick} />
            Is Header
        </DialogContentText>
        }
        {checked ?
        <DialogContentText>
          <Checkbox checked={headerOnlyChecked} disabled={true}
          onClick={handleHeaderOnlyCheck} />
            Only Header
        </DialogContentText>
        :
        <DialogContentText>
          <Checkbox checked={headerOnlyChecked}
          onClick={handleHeaderOnlyCheck} />
            Only Header
        </DialogContentText>
        }
        <DialogContentText>
          <Checkbox
            checked={newField.isRequired}
            onChange={() => setNewField({ ...newField, isRequired: !newField.isRequired })}
          />
            Is Required
          </DialogContentText>
        <DialogContentText>
          <div onClick={toggleColorPicker}>
            <span>Selection Options:</span>
            <br />
            {/* <ChromePicker color={newField.bgColor} onChange={handleColorChange} /> */}
            <Select 
              className="formSelectOptions"
              value={selectedOption}
              onChange={handleSelectionChange}
              label="Select an option"
            >
              <MenuItem value="blank">Blank</MenuItem>
              <MenuItem value="all">All (Yes, No, N/A)</MenuItem>
              <MenuItem value="yesno">Yes/ No</MenuItem>
              <MenuItem value="yes">Yes</MenuItem>
              <MenuItem value="no">No</MenuItem>
              <MenuItem value="F">F</MenuItem>
            </Select>
          </div>
        </DialogContentText>
        <DialogContentText>
          <Checkbox 
          checked={extraValue} 
          onClick={handleExtraValueCheck} />
            Extra Value (Drop-Down)
        </DialogContentText>
        {extraValue ?
        <DialogContentText>
          <span>How many extra values:</span>
          <input type="number" 
            className="formSelectOptions"
            value={extraValuesRange}
            defaultValue={1}
            onChange={(e) => setExtraValuesRange(e.target.value)}
          />
        </DialogContentText>
        : null
        }
        {extraValue && extraValuesRange ? 
         Array.from({ length: extraValuesRange }, (_, index) => (
          <>
          <div key={index} className="extraValueContainer">
          <TextField
            style={{ width: "1000px" }}
            key={index}
            label={`Extra Value ${index + 1}`}
            value={extraValues[index]}
            onChange={(e) => handleExtraValueChange(index, e.target.value)}
          />
          <Select
            className="formSelectOptions"
            label="Select value type"
            value={extraValuesType[index]}
            onChange={(e) => handleExtraValueTypeChange(index, e.target.value)}
          >
            <MenuItem value="kPa">kPa</MenuItem>
            <MenuItem value="A">A</MenuItem>
            <MenuItem value="V">V</MenuItem>
            <MenuItem value="L/min">L/min</MenuItem>
            <MenuItem value="kW">kW</MenuItem>
            <MenuItem value="rmp">rmp</MenuItem>
            <MenuItem value="ml">ml</MenuItem>
            <MenuItem value="sec">sec</MenuItem>
            <MenuItem value="rpm">rpm</MenuItem>
            <MenuItem value="°C">°C</MenuItem>
            <MenuItem value="°L/s">L/s</MenuItem>
            <MenuItem value="Hrs">Hrs</MenuItem>
            <MenuItem value="F">F</MenuItem>
          </Select>
      </div>
      </>
         ))
        : null
        }
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={addField} color="primary">Add</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default FormBuilder;

import Dexie from 'dexie';

// Initialize the database
const db = new Dexie('AppDB');

db.version(1).stores({
  users: 'id++, email, password, userData',
  config: 'id, smtp_server, smtp_port, smtp_username, smtp_password, approve, receive_jobs, messageboard, company_name, reg_no, vat_no, company_contacts, company_email, compnay_address, site_url',
  clients: 'id++, clientid, client_name, contact_person, contact_number, email, status',
  jobs: 'jobid++, user, client_name, job_type, start_time, end_time, status, clientid, userid, email',
  inspection: 'id++, template_name, job_id, signature_name, client_signature, inspect_signature, inspection_data, is_multi_sign',
  forms: 'id++, form_name, form_data',
  inspection_category: 'id++, category, status',
  site_visit: 'jobid++, clientid, userid, status'
});

// .upgrade(tx => {
//   // Create an index for the 'userid' field after the database is opened
//   tx.jobs.createIndex('userid');
// });

function getTwoWeeksAgoDate() {
  const date = new Date();
  date.setDate(date.getDate() - 14);
  return date;
}

export const formatDateTime = (date) => {
  const pad = (num, size = 2) => num.toString().padStart(size, '0');
  const padMillis = (num, size = 6) => num.toString().padStart(size, '0');

  const year = date.getFullYear();
  const month = pad(date.getMonth() + 1);
  const day = pad(date.getDate());
  const hours = pad(date.getHours());
  const minutes = pad(date.getMinutes());
  const seconds = pad(date.getSeconds());
  const milliseconds = padMillis(date.getMilliseconds(), 3);

  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}.${milliseconds}`;
};

export const addUser = async (user) => {
  await db.users.put(user);
};

export const getUser = async (email, password) => {
  return await db.users.get({ email, password });
};

export const saveSettingInfo = async (data) => {
  try {
      await db.config.put(data);
  } catch (error) {
      console.error("Error saving config from DexieDB:", error);
  }
};


export const saveSiteVisit = async (clientid, userid, status, jobid) => {
  try {
      const id = await db.site_visit.add({
          clientid: clientid,
          userid: userid,
          status: status,
          jobid: jobid
      });
      console.log(`Site visit saved with id ${id}`);
  } catch (error) {
      console.error('Failed to save site visit:', error);
  }
};

export const getSiteVisits = async () => {
  try {
    const lastRecord = await db.site_visit
    .where({ status: 1 })
    .reverse()
    .sortBy('id')
    .then((results) => results[0]);

  return lastRecord;
  } catch (error) {
      console.error("Error fetching clients from DexieDB:", error);
  }
};

export const getCompletedSiteVisits = async () => {
  try {
    const lastRecord = await db.site_visit
    .where({ status: 2 })
    .reverse()
    .sortBy('id')
    .then((results) => results[0]);

  return lastRecord;
  } catch (error) {
      console.error("Error fetching clients from DexieDB:", error);
  }
};

export const removeSiteVisitsPerUserId = async (userid) => {
  try {
    await db.site_visit.where({userid: userid}).delete();
    console.log(`Record(s) for userId ${userid} removed successfully.`);
  } catch (error) {
    console.error(`Failed to remove record(s) for userId ${userid}:`, error);
  }
};

export const saveJobForm = async (formName, clientid, userid) => {
  try {
    const now = new Date();
    const formattedStartTime = formatDateTime(now);
      const job = {
          user: '',
          clientid: clientid,
          userid: userid,
          client_name: '',
          job_type: formName,
          start_time: formattedStartTime,
          end_time: null,
          status: 1, 
          email: ''
      };
      const jobId = await db.jobs.add(job);
      console.log('Job saved successfully. Job ID:', jobId);
      return jobId; 
  } catch (error) {
      console.error('Failed to save job:', error);
      throw error; 
  }
};


export const saveInspectionForm = async (formData, templateName, jobId) => {
  try {
    if (!jobId) {
      throw new Error("Job ID is required.");
    }

    const inspectionData = {
      formData: formData,
      template_name: templateName,
      job_id: jobId
    };
    const existingInspection = await db.inspection.where({ job_id: jobId }).first();

    if (existingInspection) {
      await db.inspection.update(existingInspection.id, inspectionData);
    } else {
      await db.inspection.add(inspectionData);
    }

    return jobId;
  } catch (error) {
    console.error('Failed to save Inspection:', error);
    throw error;
  }
};

export const getSettingInfo = async () => {
  try {
     return await db.config.get({ id: 1 });
  } catch (error) {
      console.error("Error fetching config from DexieDB:", error);
  }
};

export const saveClientInfo = async (clients) => {
  try {
      if (Array.isArray(clients)) {
          const newClients = [];
          for (const client of clients) {
              const existingClient = await db.clients.where({ clientid: client.clientid }).first();
              if (!existingClient) {
                  if (!client.id) {
                      // Generate an id if not present
                      client.id = crypto.randomUUID();
                  }
                  newClients.push(client);
              }
          }

          if (newClients.length > 0) {
              await db.clients.bulkAdd(newClients);
              console.log('New clients saved:', newClients);
          } else {
              console.log('No new clients to save.');
          }
      }
  } catch (error) {
      console.error('Error saving clients to DexieDB:', error);
  }
};

export const getClientsInfo = async () => {
  try {
    return await db.clients.where({ status: 1 }).toArray();
  } catch (error) {
      console.error("Error fetching clients from DexieDB:", error);
  }
};


export const getJobsInfo = async () => {
  try {
    return await db.jobs.toArray();
  } catch (error) {
    console.error("Error fetching all jobs from DexieDB:", error);
  }
};

export const getJobsToDisplay = async () => {
  try {
    const jobs = await db.jobs.toArray();
    
    if (!jobs) {
      throw new Error("No jobs found in the database.");
    }
    
    const jobsWithInspections = await Promise.all(jobs.map(async job => {
      const inspection = await db.inspection.where('job_id').equals(job.jobid).first();
      return inspection ? { ...job, inspectionExists: true } : null;
    }));
    
    const filteredJobsWithInspections = jobsWithInspections.filter(job => job !== null);
    return filteredJobsWithInspections;
  } catch (error) {
    console.error("Error fetching all jobs or checking inspections from DexieDB:", error);
  }
};


export const getFormsInfo = async () => {
  try {
    return await db.forms.toArray();
  } catch (error) {
    console.error("Error fetching all forms from DexieDB:", error);
  }
};

export const getInspectionCategoryInfo = async () => {
  try {
    return await db.inspection_category.toArray();
  } catch (error) {
    console.error("Error fetching all inspection category from DexieDB:", error);
  }
};

export const getInspectionsInfo = async () => {
  try {
    return await db.inspection.toArray();
  } catch (error) {
    console.error("Error fetching all inspections from DexieDB:", error);
  }
};

export const getInspectionsWhereInfo = async (job_id) => {
  try {
    return await db.inspection.toArray();
  } catch (error) {
    console.error("Error fetching inspections for job ID from DexieDB:", error);
    return [];
  }
};

export const getJobsWhereInfo = async (userid) => {
  try {
    return await db.jobs.where({ userid: userid }).toArray();
  } catch (error) {
    console.error("Error fetching jobs with where clause from DexieDB:", error);
  }
};

export const getCurrentJob = async (jobid) => {
  try {
    return await db.jobs.where({ jobid: jobid }).toArray();
  } catch (error) {
    console.error("Error fetching current jobs with where clause from DexieDB:", error);
  }
};

export const getJobsWhereInfoStatus1 = async (userid) => {
  try {
    return await db.jobs.where({ userid: userid, status: 1 }).toArray();
  } catch (error) {
    console.error("Error fetching jobs with where clause from DexieDB:", error);
  }
};

export const getMultiSignJobs = async (userid) => {
  try {
    const jobs = await db.jobs.where({ userid: userid, status: 2 }).toArray();
    const users = await db.users.toArray();
    const clients = await db.clients.toArray();
    const inspections = await db.inspection.toArray();

    const filteredJobs = inspections
      .filter(inspection => {
        const job = jobs.find(j => j.jobid === inspection.job_id);
        return job && !inspection.client_signature
      })
      .map(inspection => {
        const job = jobs.find(j => j.jobid === inspection.job_id);
        const user = users.find(u => u.id === job.userid);
        const client = clients.find(c => c.clientid === job.clientid);

        return {
          jobid: job.jobid,
          user: user?.fullname,
          client_name: client?.client_name,
          job_type: job.job_type,
          start_time: job.start_time,
          end_time: job.end_time,
          status: job.status,
          clientid: job.clientid,
          userid: job.userid,
          email: user?.username,
        };
      });

    return filteredJobs;
  } catch (error) {
    console.error("Error fetching jobs with where clause from DexieDB:", error);
  }
};


export const syncJobsInfo = async (data) => {
  try {
    await db.transaction('rw', db.jobs, async () => {
      const existingIds = await db.jobs.toCollection().primaryKeys();
      const filteredData = data.filter(item => !existingIds.includes(item.jobid));

      if (filteredData.length > 0) {
        await db.jobs.bulkAdd(filteredData);
        console.log('jobs information synced to DexieDB successfully');
      } else { 
        console.log('No jobs found to sync to DexieDB');
      }
    });
  } catch (error) {
    if (error.name === 'BulkError') {
      const duplicateKeys = error.failures.map(failure => failure.key);
      console.warn(`Duplicate keys found: ${duplicateKeys.join(', ')}`);
    } else {
      console.error("Error saving jobs info to DexieDB:", error);
    }
  }
};

export const syncFormsInfo = async (data) => {
  try {
    await db.transaction('rw', db.forms, async () => {
      const existingIds = await db.forms.toCollection().primaryKeys();
      const filteredData = data.filter(item => !existingIds.includes(item.id));

      if (filteredData.length > 0) {
        await db.forms.bulkAdd(filteredData);
        console.log('Forms information synced to DexieDB successfully');
      } else { 
        console.log('No forms found to sync to DexieDB');
      }
    });

  } catch (error) {
    if (error.name === 'BulkError') {
      const duplicateKeys = error.failures.map(failure => failure.key);
      console.warn(`Duplicate keys found: ${duplicateKeys.join(', ')}`);
    } else {
      console.error("Error saving forms info to DexieDB:", error);
    }
  }
};


export const syncInspectionCategoryInfo = async (data) => {
  try {
    await db.transaction('rw', db.inspection_category, async () => {
      const existingIds = await db.inspection_category.toCollection().primaryKeys();
      const filteredData = data.filter(item => !existingIds.includes(item.id));

      if (filteredData.length > 0) {
        await db.inspection_category.bulkAdd(filteredData);
        console.log('Inspection Category information synced to DexieDB successfully');
      } else {
        console.log('No inspection categories found to synced to DexieDB');
      }
    });
  } catch (error) {
    if (error.name === 'BulkError') {
      const duplicateKeys = error.failures.map(failure => failure.key);
      console.warn(`Duplicate keys found: ${duplicateKeys.join(', ')}`);
    } else {
      console.error("Error saving inspection category info to DexieDB:", error);
    }
  }
};

export const syncInspectionsInfo = async (data) => {
  try {
    await db.transaction('rw', db.inspection, async () => {
      const existingIds = await db.inspection.toCollection().primaryKeys();
      const filteredData = data.filter(item => !existingIds.includes(item.id));

      if (filteredData.length > 0) {
        await db.inspection.bulkAdd(filteredData);
        console.log('Inspections information synced to DexieDB successfully');
      } else {
        console.log('No inspections found to synced to DexieDB');
      }
    });
  } catch (error) {
    if (error.name === 'BulkError') {
      const duplicateKeys = error.failures.map(failure => failure.key);
      console.warn(`Duplicate keys found: ${duplicateKeys.join(', ')}`);
    } else {
      console.error("Error saving inspections info to DexieDB:", error);
    }
  }
};

export const updateJobAndUser = async (job_id, job_status, user_id) => {
  try {
    await db.transaction('rw', db.jobs, db.users, async () => {
      const updatedJobCount = await db.jobs.update(job_id.jobId, {
        end_time: formatDateTime(new Date()),
        status: job_id.JobStatus
      });

      if (updatedJobCount > 0) {
        const updatedUserCount = await db.users.update(job_id.userId, { current_job: 0 });

        if (updatedUserCount === 0) {
          console.warn(`User with id ${job_id.userId} not found.`);
        }
      } else {
        console.warn(`Job with id ${ job_id.jobId} not found.`);
      }
    });
  } catch (error) {
    console.error('Failed to update job and user:', error);
  }
};

export const updateClientSignature = async (job_id, signature_name, client_signature) => {
  try {
    const { jobId, clientSignature, signatureName } = job_id;
    const existingInspection = await db.inspection.get({ job_id: jobId });
    if (!existingInspection) {
      console.error('No inspection found with the given job_id:', jobId);
      return;
    }

    const updatedInspection = await db.inspection.update(existingInspection.id, {
      signature_name: signatureName,
      client_signature: clientSignature
    });

    if (updatedInspection > 0) {
      return updatedInspection
    } else {
      console.error('No inspection found with the given job_id');
    }
  } catch (error) {
    console.error('Failed to update inspection:', error);
  }
};

export const updateClientMultiSignature = async (job_id) => {
  try {
    for (let i = 0; i < job_id.length; i++) {
      const { jobId, signatureName } = job_id[i];

      const existingInspection = await db.inspection.get({ job_id: jobId });
      if (!existingInspection) {
        console.error('No inspection found with the given job_id:', jobId);
        continue;
      }

      const updatedInspection = await db.inspection.update(existingInspection.id, {
        signature_name: signatureName,
        is_multi_sign: true
      });

      if (updatedInspection > 0) {
        console.log('Successfully updated inspection for job_id:', jobId);
        return true
      } else {
        console.error('Failed to update inspection for job_id:', jobId);
        return false
      }
    }
  } catch (error) {
    console.error('Failed to update inspections:', error);
  }
};

export const updateInspectSignature = async (job_id) => {
  try {
    const { jobId, inspectSignature } = job_id;
    const existingInspection = await db.inspection.get({ job_id: jobId });
    if (!existingInspection) {
      console.error('No inspection found with the given job_id:', jobId);
      return;
    }

    const updateCount = await db.inspection.update(existingInspection.id, {
      inspect_signature: inspectSignature
    });

    if (updateCount > 0) {
      return updateCount;
    } else {
      console.error('Failed to update inspection: No rows affected');
    }
  } catch (error) {
    console.error('Failed to update inspection:', error);
  }
};

export const updateJobs = async (job_id, job_type) => {
  try {
    await db.transaction('rw', db.jobs, async () => {
      const updatedJobCount = await db.jobs.update(job_id, {
        job_type: job_type
      });

      if (updatedJobCount > 0) {
        return job_id
      } else {
        console.warn(`Job with id ${ job_id.jobId} not found.`);
      }
    });
  } catch (error) {
    console.error('Failed to update job:', error);
  }
};

export const updateSiteVisits = async (jobid) => {
  try {
    const key = jobid["jobid"];
    if (typeof key !== 'string' && typeof key !== 'number' && !(key instanceof Date) && !Array.isArray(key)) {
      throw new Error('Invalid key provided. Keys must be of type string, number, Date or Array<string | number | Date>.');
    }
    await db.transaction('rw', db.site_visit, async () => {
      const updatedJobCount = await db.site_visit.update(key, {
        status: 2
      });

      if (updatedJobCount > 0) {
        console.log(`Job with jobid ${key} updated successfully.`);
        return key;
      } else {
        console.warn(`Job with jobid ${key} not found.`);
      }
    });
  } catch (error) {
    console.error('Failed to update job:', error.message);
  }
};


export default db;
import React, { useState, useEffect, useRef } from "react";
import styles from '../inspection/Inspection.module.scss';
import { useHistory } from "react-router-dom";
import SignaturePad from 'signature_pad';
import { toast } from 'react-toastify';
import { post } from "../../api";
import  secureLocalStorage  from  "react-secure-storage";
import { updateClientMultiSignature, getMultiSignJobs } from '../../dexieDB';


const MultiSign = () => {
    const history = useHistory();
    const canvasRef = useRef(null);
    const signaturePadRef = useRef(null);
    const [jobs, setJobs] = useState([]);
    const [signatureName, setSignatureName] = useState('');
    const userid = JSON.parse(secureLocalStorage.getItem('user')).id;
    const [jobSelected, setJobSelected] = useState([]);
    const isOnline = navigator.onLine;

    const handleJobSelect = (jobId) => {
        const isSelected = jobSelected.includes(jobId);
        if (isSelected) {
            setJobSelected(jobSelected.filter(id => id !== jobId));
        } else {
            setJobSelected([...jobSelected, jobId]);
        }
    };

    const SaveMultiSigs = async (jobId) => {
        if (isOnline) {
            if (signaturePadRef.current) {
                const signatureData = signaturePadRef.current.toDataURL();

                if (signaturePadRef.current.isEmpty()) {
                    toast.error("No client signature has been drawn");
                }
                else {
                    toast.dismiss();

                    const res = await post('/inspection_save_signature', { clientSignature: signatureData.split(',')[1], jobId: jobId, signatureName });
                        if (res.ok)
                        {
                            history.push({ pathname: '/clients' });
                            toast.success("Client signature saved successfully");
                        }
                        else {
                            toast.error("Unable to save client signature");
                        }
                        return res.ok;
                    }
            }
        } else {
            let sigs = await updateClientMultiSignature({ jobId });
            if (sigs) {
                toast.success("client signature has been successfully updated");
                return true
            } else {
                toast.error("unable to update client signature");
                return false
            }
        } 
    };

    const GoToClients = () => {
        history.push({ pathname: '/clients' })
    };

    useEffect(() => {
        const get_jobs = async () => {
            if (isOnline) {
                const resp = await post(`/jobs_multi_sign`, { userid });
                if (resp.ok) {
                    setJobs(await resp.json());
                }
                else {
                    toast.error("Unable to get jobs list");
                }
            }
            else {
                const localJobs = await getMultiSignJobs(userid);
                setJobs(localJobs);
            }
        };
        get_jobs();
    }, [userid, isOnline]);

    useEffect(() => {
        const canvas = canvasRef.current;
        const signaturePad = new SignaturePad(canvas);

        signaturePadRef.current = signaturePad;
        signaturePad.minWidth = 1;
        signaturePad.maxWidth = 3;
    
        return () => {
          signaturePad.clear();
        };
      }, []);
    
    const handleClear = () => {
        const signaturePad = new SignaturePad(canvasRef.current);
        signaturePad.clear();
    };

    return (
        <div>
            <div className={styles.main}>
            <h4 className={styles.warning}>Please find a list off jobs done for this client within the last two weeks that wasn't signed yet. Please choose only the jobs you want to sign for:</h4>
                <form>
                    <table className={styles.my_table}>
                        <thead>
                            <tr>
                                <th>Job ID</th>
                                <th>Name</th>
                                <th>Valve Chamber</th>
                                <th>Valve Description</th>
                                <th>Time Started</th>
                                <th>Time Finished</th>
                                <th>Select</th>
                            </tr>
                        </thead>
                        <tbody>
                            {jobs.map((job) => (
                                <tr key={jobs.jobid}
                                    onClick={() => handleJobSelect(job.jobid)}>
                                    <td>{job.jobid}</td>
                                    <td>{job.job_type}</td>
                                    <td>{job.valve_chamber}</td>
                                    <td>{job.valve_description}</td>
                                    <td>{job.start_time}</td>
                                    <td>{job.end_time}</td>
                                    <td>
                                        <label>
                                            <input type="checkbox" name="jobs_select" />
                                        </label>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table><br/>
                    <label className={styles.spacing}>I hereby confirm that I will multi sign for all the selected Jobs above and I agree that my signature below may be used for only the selected jobs.</label><br /><br />
                    <label className={styles.spacing} >Client Name And Surname</label><br />
                    <input className={styles.spacing} type="text" onChange={(e) => setSignatureName(e.target.value)} /><br /><br />
                    <label className={styles.spacing}>Client signature</label>
                    <div className={styles.signature_container}>
                        <button className={styles.signature_clear} onClick={handleClear}>Clear</button>
                        <canvas className={styles.signature_canvas} ref={canvasRef} width={400} height={200}></canvas>
                        <div className={styles.signature_label_container}>
                            <p>Please sign above</p>
                        </div>
                    </div>
                    <div className={styles.btn_container}>
                        <button className={styles.btn_signature} onClick={() => SaveMultiSigs(jobSelected)}>Next</button>
                        <button className={styles.btn_signature} onClick={() => GoToClients()}>Back to Clients</button>
                    </div>
                </form>
            </div>
        </div>
    )
};

export default MultiSign;
import './App.css';
import React, { useEffect, useState } from "react";
import { Switch, Route, BrowserRouter as Router, Redirect } from 'react-router-dom';
import { UserProvider } from './context';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

import Login from './pages/login/Login';
import NotFound from './pages/not-found/NotFound';

import FormBuilder from './FormBuilder';
import InspectionCategory from './pages/inspection_category/InspectionCategory';
import InspectionCatNew from './pages/inspection_category/InspectionCatNew';
import InspectionCatEdit from './pages/inspection_category/InspectionCatEdit';
import { get, post } from "./api";
import Navbar from './pages/navbar/NavBar';
import Register from './pages/register/Register';
import RegisterApprove from './pages/register/RegisterApprove';
import PasswordReset from './pages/password_reset/PasswordReset';
import RequestPassword from './pages/request_password/RequestPassword';
import Home from './pages/home/Home';
import Settings from './pages/settings/Settings';
import Clients from './pages/clients/Clients';
import ClientNew from './pages/clients/ClientNew';
import Forms from './pages/forms/Forms';
import Jobs from './pages/jobs/Jobs';
import InspectionView from './pages/inspection/InspectionView';

import 'react-toastify/dist/ReactToastify.css'; // Import CSS styles
import { ToastContainer } from 'react-toastify';
import  secureLocalStorage  from  "react-secure-storage";
import InspectionSignature from './pages/inspection/InspectionSignature.jsx';
import ClientSignature from './pages/inspection/ClientSignature';
import InspectionConfirm from './pages/inspection/InspectionConfirm.jsx';
import MultiSign from './pages/inspection/MultiSign.jsx';
import SysAdmin from './pages/sysadmin/SysAdmin.jsx';
import SysAdminEdit from './pages/sysadmin/SysAdminEdit.jsx';
import SyncedData from './pages/offlinesync/OfflineSyncData.jsx';
import Profile from './pages/profile/Profile';
import { syncJobsInfo, syncFormsInfo, getInspectionsInfo, syncInspectionCategoryInfo, getJobsInfo, getCompletedSiteVisits } from '../src/dexieDB.js';


const Pages = () => (
  <Switch>
    <div>
      <Route path="/" exact>
        {secureLocalStorage.getItem('user') ? <Redirect to="/home" /> :  null}
      </Route>
      <Route path="/home">
        <Navbar />
        <Home />
      </Route>
      <Route path="/clients">
        <Navbar />
        <Clients />
      </Route>
      <Route path="/client_new">
        <Navbar />
        <ClientNew />
      </Route>
      <Route path="/settings">
        <Navbar />
        <Settings />
      </Route>
      <Route path="/login" component={Login}/>
      <Route path="/register_approve/:email" component={RegisterApprove}/>
      <Route path="/register" component={Register}/>
      <Route path="/password_reset" component={PasswordReset}/>
      <Route path="/request_password/:email_code" component={RequestPassword}/>
      <Route path="/form-builder">
        <Navbar />
        <FormBuilder />
      </Route>
      <Route path="/inspection_category">
        <Navbar />
        <InspectionCategory />
      </Route>
      <Route path="/inspection_cat_new">
        <Navbar />
        <InspectionCatNew />
      </Route>
      <Route path="/inspection_cat_edit">
        <Navbar />
        <InspectionCatEdit />
      </Route>
      <Route path="/forms">
        <Navbar />
        <Forms />
      </Route>
      <Route path="/inspection_signature">
        <Navbar />
        <InspectionSignature />
      </Route>
      <Route path="/client_signature">
        <Navbar />
        <ClientSignature />
      </Route>
      <Route path="/inspection_confirm">
        <Navbar />
        <InspectionConfirm />
      </Route>
      <Route path="/inspection_view/:jobtype/:id">
        <Navbar />
        <InspectionView />
      </Route>
      <Route path="/jobs">
        <Navbar />
        <Jobs />
      </Route>
      <Route path="/sysadmin">
        <Navbar />
        <SysAdmin />
      </Route>
      <Route path="/sysadmin_edit">
        <Navbar />
        <SysAdminEdit />
      </Route>
      <Route path="/multi_sign">
        <Navbar />
        <MultiSign />
      </Route>
      <Route path="/synced_data">
        <Navbar />
        <SyncedData />
      </Route>
      <Route path="/profile">
        <Navbar />
        <Profile />
      </Route>
      <Route path="*" element={NotFound} />
    </div>
  </Switch>
);



function App() {
  const [user, setUser] = useState();
  const [redirectToLogin, setRedirectToLogin] = useState(false);
  const [jobs, setJobs] = useState({});
  const [forms, setForms] = useState({});
  const [inspectionsCategory, setInspectionsCategory] = useState({});

  const fetchUser = async () => {
    const path = window.location.pathname;
    if (path === "/password_reset" || path === "/register" || path.startsWith("/request_password/") || path.startsWith("/register_approve/")) {
      setUser(null);
    } else {
      const res = await get(`/user${secureLocalStorage.getItem('user')}`);
      if (res.ok) {
        const userData = await res.json();
        setUser(userData);
        setRedirectToLogin(false);
      } else {
        setRedirectToLogin(true);
      }
    }
  };

  const syncInspectionsToDB = async () => {
    if (navigator.onLine) {
      const res = await getInspectionsInfo();
      const jobs = await getJobsInfo();
      const site_visits = await getCompletedSiteVisits();
      if (res) {
        for (const inspect of res) {
          await post('/sync_inspections', inspect);
        }
        
        await post('/sync_jobs', jobs);
        await post('/site_visits_update', site_visits);
        await post('/reset_user_jobs');
        return true;
      }
    }
  };

  useEffect(() => {
    if (user && user !== undefined) {
      const getJobs = async () => {
        const resp = await post(`/jobs_list`, { userid: user.id, filterlist: '' });

        if (resp.ok) {
          let jobs = await resp.json(); 
          setJobs(jobs);
          syncJobsInfo(jobs);
        }
      };

    getJobs();
  }
  }, [user]);

  useEffect(() => {
    if (user && user !== undefined) {
      const getForms = async () => {
        const resp = await get('/forms_list');

        if (resp.ok) {
          let forms = await resp.json(); 
          setForms(forms);
          syncFormsInfo(forms);
        }
      };

      getForms();
  }
  }, [user]);

  useEffect(() => {
    if (user && user !== undefined) {
      const getInspectionsCategory = async () => {
        const resp = await get('/inspection_category_list');

        if (resp.ok) {
          let inspectionsCategory = await resp.json(); 
          setInspectionsCategory(inspectionsCategory);
          syncInspectionCategoryInfo(inspectionsCategory);
        }
      };

      getInspectionsCategory();
  }
  }, [user]);

  useEffect(() => {
    const storedUser = JSON.parse(secureLocalStorage.getItem('user'));
    if (storedUser) {
      setUser(storedUser);
    } else {
      fetchUser();
    }
  }, []);

  useEffect(() => {
    const intervalId = setInterval(() => {
      syncInspectionsToDB();
    }, 10000);
    return () => clearInterval(intervalId);
  }, []); 

  return(
    <DndProvider backend={HTML5Backend}>
    <React.StrictMode>
      <UserProvider>
        <Router>
          {redirectToLogin && <Redirect to="/login" />}
          <Pages />
        </Router>
      <ToastContainer position='bottom-right'/>
      </UserProvider>
    </React.StrictMode>
    </DndProvider>
  );
}

export default App;
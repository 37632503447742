export function setLocalStorageItem(key, value) {
    localStorage.setItem(key, value);
};

export function getLocalStorageItem(key) {
    const value = localStorage.getItem(key);
    return value;
};

export function removeLocalStorageItem(key) {
    const value = localStorage.removeItem(key);
    return value;
};
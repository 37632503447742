import React, { useState } from 'react';
import { post } from '../../api';
import { toast } from 'react-toastify';
import styles from './Register.module.scss';
import { useParams } from 'react-router-dom';

const RegisterApprove = ({ history }) => {
    const { email } = useParams();
    const submit = async (event) => {
        event.preventDefault();
        const res = await post('/register_approve', { email });
        if (res.ok)
        {
            toast.success("Approved.", res.statusText);
        }
        else if (res.status === 500)
        {
            toast.error("Error: ", res.statusText);
        }
        else if (res.status === 404)
        {
            toast.error("Email already registered.");
        }
        else
        {
            toast.error("Something went wrong with resetting your password. Please contact system administrator.");
        }
        return res.ok;
    };

    return (
        <div className={styles.main}>
            <input className={styles.chk} type="checkbox" id="chk" aria-hidden="true" />
            <form method="post" onSubmit={submit}>
                <div align="center">
                    <label className={styles.RegLabel} for="chk" aria-hidden="true">Approve Registration</label>
                </div>
                <button className={styles.RegButton} name="reset_password">Approve Registration</button>
                <button className={styles.RegButton} onClick={() => history.push("/login")} name="back">Back</button>
            </form>
        </div>
    )

};

export default RegisterApprove;
import React, { useState } from 'react';
import styles from './Register.module.scss';
import { post } from '../../api';
import { toast } from 'react-toastify';


const Register = ({ history }) => {
    const [email, setEmail] = useState('');
    const [name, setName] = useState('');
    const [password, setPassword] = useState('');
    const [password2, setPassword2] = useState('');

    const submit = async (event) => {
        event.preventDefault();
        const res = await post('/register', { email, name, password, password2 });
        if (res.ok)
        {
            toast.success("Welcome mail was sent. Please check your mailbox and spam folder.", res.statusText);
        }
        else if (res.status === 404)
        {
            toast.error("Email already registered.");
        }
        else if (res.status === 400)
        {
            toast.error("The password and confirm password does not match.");
        }
        else
        {
            toast.error("Something went wrong with resetting your password. Please contact system administrator.");
        }
        return res.ok;
    };

    return (
        <div className={styles.main}>
            <input className={styles.chk} type="checkbox" id="chk" aria-hidden="true"/>
            <form method="post" onSubmit={submit}>
                <div align="center">
                    <label className={styles.RegLabel} for="chk" aria-hidden="true">Sign up</label>
                    <label className={styles.my_labelRed}>Remember to check your email's spambox.</label><br/>
                    <input type="email" name="email" placeholder="Email" onChange={(e) => setEmail(e.target.value)} required/>
                    <input type="text" name="name" placeholder="Name and Surname" onChange={(e) => setName(e.target.value)} required/>
                    <input type="password" name="password" placeholder="Password" onChange={(e) => setPassword(e.target.value)} required/>
                    <input type="password" name="password2" placeholder="Confirm Password" onChange={(e) => setPassword2(e.target.value)} required/>
                </div>
                <button className={styles.RegButton} name="register">Register</button>
                <button className={styles.RegButton} onClick={() => history.push("/login")} name="back">Back</button>
            </form>
        </div>
    )
};

export default Register;
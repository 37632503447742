import React, { useState, useEffect } from 'react';
import styles from './SysAdmin.module.scss';
import { post } from '../../api';
import { toast } from 'react-toastify';
import { useHistory } from "react-router-dom";
import { removeLocalStorageItem } from '../utils/Utils';
import { removeSiteVisitsPerUserId } from '../../dexieDB';

const SysAdminEdit = () => {
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [user, setUser] = useState([]);
  const [password, setPassword] = useState('');
  const [userStatus, setUserStatus] = useState('');
  const history = useHistory();
  const user_id = history.location.state?.userid;

  useEffect(() => {
    const get_user = async () => {
        const resp = await post(`/user`, { filterlist: `WHERE id = ${user_id}` });
        if (resp.ok) {
          setUser(await resp.json());
        }
    };
    get_user();
  }, [user_id]);

  const clearUserJobs = async (event) => {
    event.preventDefault();
    const res = await post('/jobs_clear_per_user', { userid: user_id });
    if (res.ok)
    {
      await removeSiteVisitsPerUserId(user_id);
      removeLocalStorageItem('active_site_visit');
      toast.success("User jobs have been cleared.", res.statusText);
    }
    else
    {
      toast.error("Something went wrong with clearing user jobs. Please contact system administrator.");
    }
    return res.ok;
  };

  const submit = async (event) => {
    event.preventDefault();
    const res = await post('/user/update', { user_id, email, password, userStatus });
    if (res.ok)
    {
        toast.success("User information has been updated.", res.statusText);
        history.push('/sysadmin');
    }
    else
    {
        toast.error("Something went wrong with updating the user information. Please contact system administrator.");
    }
    return res.ok;
  };

  useEffect(() => {
    if (user !== null && user !== undefined) {
      user.map((user) => {
        setEmail(user.username);
        setName(user.fullname);
        setUserStatus(user.status);
        return null;
      })
    }
  }, [user]);

  return (
    <div>
        <form method="post" onSubmit={submit}>
          {user.map((user) => (
            <div className={styles.main} key={user.id}>
                <h1 className={styles.myheading}>Edit User : {name}</h1>
                <>
                  <label className={styles.my_label}>Name:</label><br/>
                  <input className={[styles.my_edit, styles.readonly].join(' ')} type="text" defaultValue={name} readOnly={true} /><br/>
                  <label className={styles.my_label}>Email:</label><br/>
                  <input className={styles.my_edit} type="text" value={email} onChange={(e) => setEmail(e.target.value)} /><br/>
                  <label className={styles.my_label}>Password:</label><br/>
                  <input className={styles.my_edit} type="text" value={password} onChange={(e) => setPassword(e.target.value)} /><br/>
                  <label className={styles.my_label}>Status: <font color="red">(1 - Active, 2 - Inactive)</font></label><br/>
                  <input className={styles.my_edit} type="text" value={userStatus} onChange={(e) => setUserStatus(e.target.value)} /><br/>
                </>
            </div>
          ))}
          <div className={styles.main}>
              <input type="submit" className={styles.btnOk} value="Save"/>
              <input type="button" className={styles.btnOk} onClick={(event) => clearUserJobs(event)} value="Clear All Jobs"/>
          </div>
        </form>
    </div>
  )
};
export default SysAdminEdit;
import React, { useEffect, useState } from 'react';
import { get } from '../../api';
import styles from './Home.module.scss';
import { saveSettingInfo, getSettingInfo } from '../../dexieDB';


const Home = () => {
    const [messageboard, setMessageboard] = useState('');
    const [company_name, setCompanyName] = useState('');
    const [company_contacts, setContactno] = useState('');
    const [company_email, setEmail] = useState('');
    const [company_address, setAddress] = useState('');
    const isOnline = navigator.onLine;
    
    useEffect(() => {
        if (isOnline) {
            const get_settings = async () => {
                const resp = await get(`/setting_info`);
                if (resp.ok)
                {
                    const x = await resp.json();
                    if (x && Object.keys(x).length > 0)
                    {
                        if (x["messageboard"] !== undefined) {
                            setMessageboard(x["messageboard"]);
                        }
                        if (x["company_name"] !== undefined) {
                            setCompanyName(x["company_name"]);
                        }
                        if (x["company_contacts"] !== undefined) {
                            setContactno(x["company_contacts"]);
                        }
                        if (x["company_email"] !== undefined) {
                            setEmail(x["company_email"]);
                        }
                        if (x["company_address"] !== undefined) {
                            setAddress(x["company_address"]);
                        }
                    }
                    saveSettingInfo(x);
                }
            };
            get_settings();
        } else {
            const getOfflineSettings = async () => {
            let settings = await getSettingInfo()
            setMessageboard(settings.messageboard);
            setCompanyName(settings.company_name);
            setContactno(settings.company_contacts);
            setEmail(settings.company_email);
            setAddress(settings.company_address);
            }
            getOfflineSettings();
        }
    }, []);

    return (
        <div>
            {messageboard ? (
                <div className={styles.main}>
                    <h1 className={styles.msg_board_heading}>Message Board</h1>
                    <p className={styles.msg_board_text}>
                        {messageboard}
                    </p>
                </div>
            ) : null}
            <div className={styles.main}>
                <img className={styles.home_logo} src={process.env.PUBLIC_URL + '/background1.png'}/>
            </div>
            <div className={styles.footer}>
                <hr/>
                    About us
                <hr/>
                    {company_name} <br/>
                    {company_contacts} <br/>
                    Email: {company_email} <br/>
                    {company_address} <br/>
                <hr/>
            </div>
        </div>
    )
};

export default Home;
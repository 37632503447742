import React, { useState, useEffect } from "react";
import { post } from '../../api';
import { useParams } from 'react-router-dom';

import { pdfjs } from 'react-pdf';
import styles from './Inspection.module.scss';
import { toast } from 'react-toastify';
import { useHistory } from "react-router-dom";
import  secureLocalStorage  from  "react-secure-storage";
import { getCurrentJob, getInspectionsWhereInfo, updateJobAndUser } from '../../dexieDB';


pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

const InspectionView = () => {
  const { jobtype, id } = useParams();
  const [formData, setFormData] = useState([]);
  const [formValues, setFormValues] = useState({});
  const [formElements, setFormElements] = useState([]);
  const history = useHistory();
  const [readOnly, setReadOnly] = useState(false);
  const user_name = JSON.parse(secureLocalStorage.getItem('user')).username;
  const userId = JSON.parse(secureLocalStorage.getItem('user')).id;
  const [jobInfo, setJobInfo] = useState([]);
  const [loading, setLoading] = useState(false);
  const isOnline = navigator.onLine;

  const handleInputChange = (element, value) => {
    setFormValues(prevValues => {
      const updatedValues = { ...prevValues };
      updatedValues[element.label] = value;
      return updatedValues;
    });
  };

  const handleFileChange = (element, event) => {
    try {
      if (!event || !event.target || !event.target.files || event.target.files.length === 0) {
        throw new Error('No files selected.');
      }
  
      const file = event.target.files[0];
      
      if (!file) {
        throw new Error('File is undefined.');
      }
  
      const reader = new FileReader();
  
      reader.onload = (readerEvent) => {
        const image = new Image();
  
        image.onload = () => {
          const canvas = document.createElement('canvas');
          const ctx = canvas.getContext('2d');
  
          let newWidth = image.width;
          let newHeight = image.height;
          const aspectRatio = image.width / image.height;
  
          if (newWidth > 800) {
            newWidth = 800;
            newHeight = newWidth / aspectRatio;
          }
          if (newHeight > 800) {
            newHeight = 800;
            newWidth = newHeight * aspectRatio;
          }
  
          canvas.width = newWidth;
          canvas.height = newHeight;
  
          ctx.drawImage(image, 0, 0, newWidth, newHeight);  
          const base64String = canvas.toDataURL('image/jpeg', 0.8); // Set quality to 1 for maximum quality
  
          handleInputChange(element, base64String);
        };
  
        image.src = readerEvent.target.result;
      };
  
      reader.readAsDataURL(file);
    } catch (error) {
      console.error('Error handling file change:', error);
    }
  };

  const handleBack = async() => {
    history.push({
      pathname: '/clients', 
      state: 
        { 
          jobId: id
      }
    })
  };

  const GoToInspectSignature = async (e) => {
    e.preventDefault();
  
    if (isOnline) {
      const res = await post('/jobs_update', { jobId: id, JobStatus: 2, userId });
      if (res.ok) {
        history.push({
          pathname: '/inspection_signature',
          state: {
            jobId: id,
            templateName: jobtype
          }
        });
      } else {
        toast.error("Unable to end job time. Please try again or contact system administrator.");
      }
    } else {
      await updateJobAndUser({ jobId: id, JobStatus: 2, userId: userId });
      toast.success("Job status saved locally. Will sync when back online.");
      history.push({
        pathname: '/inspection_signature',
        state: {
          jobId: id,
          templateName: jobtype
        }
      });
    }
  };

  const handleNext = async(e) => {
    e.preventDefault();
    setLoading(true);
    let errorOccurred = false;

    for (const element of formElements) {
      if (element.isRequired && (element.type === 'text' || element.isSelection === 'all' || element.isSelection === 'yesno' || element.isSelection === 'F' || element.isSelection === 'yes')) {
        const value = formValues[element.label];

        if (!value || value.trim() === '') {
          errorOccurred = true;
          toast.error(`Please fill in ${element.label}.`);
          break; 
      }
      }
    }

    if (errorOccurred) {
      setLoading(false); 
      return;
    }

    try {
      const res = await post('/inspection_save', { formData, formValues, templateName: jobtype, jobId: id });
      if (res.ok)
      {
      toast.success("Inspection has been saved successfully.", res.statusText);
      setReadOnly(true);
      }
      else
      {
      setLoading(false);
      toast.error("Something went wrong with saving the inspection form. Please try again or contact system administrator.");
      }
    } catch (error) {
      setLoading(false);
      toast.error("Something went wrong with saving the inspection form. Please try again or contact system administrator.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (isOnline) {
      const get_forms = async () => {
      const resp = await post(`/inspection_list`, { id });
      if (resp.ok) {
          setFormData(await resp.json());
      }
    };
    get_forms();
    } else {
        const fetchData = async () => {
        try {
          const inspectionData = await getInspectionsWhereInfo(id);
            if (!Array.isArray(inspectionData)) {
              throw new Error("Inspection data is not an array.");
            }

            const filteredInspections = inspectionData.filter(inspection => {
              return inspection.job_id.toString() === id.toString(); 
            });
            setFormData(filteredInspections);
           return filteredInspections;
        } catch (error) {
            console.error("Error fetching inspection from DexieDB:", error);
        }};
        fetchData();
    } 
  }, [isOnline, id, jobtype]);
  
  useEffect(() => {
    if (isOnline) {
      const get_job_info = async () => {
      const resp = await post(`/jobs_info`, { jobid: id });
      if (resp.ok) {
          setJobInfo(await resp.json());
      }
      };
      get_job_info();
    } else {
        const fetchData = async () => {
        try {
            const JobsInfoData = await getCurrentJob(id);
            setJobInfo(JobsInfoData);
        } catch (error) {
            console.error("Error fetching jobs from DexieDB", error);
        }};
        fetchData();
    } 
    }, [isOnline, id]); 
  
  useEffect(() => {
    if (isOnline) {
      const allFields = formData.flatMap(item => item.inspection_data.fields);
      setFormElements([...allFields]);
    } else {
      const allFields = formData.flatMap(item => item.formData.fields);
      setFormElements([...allFields]);
    }
  }, [formData]);

  return (
    <>
    <div className={styles.main}>
      <form>
        {formElements.map((element, index) => (
        <div key={index}>
          {element.isHeader || element.isHeaderOnly || !element.isSelection === '' ? (
            <h3
              className={styles.jobs_section}
              style={{
                textAlign: element.isSpecialField ? 'left' : 'center',
                backgroundColor: element.bgColor,
              }}
            >
              {element.label}
            </h3>
          ) 
          : (
            element.type !== 'button' && (
              <label className={`jobslabel ${element.isSpecialField ? 'special' : ''}`} required={element.isRequired}>
                {element.label}:
              </label>
            )
          )
          }
            <div className="textbox-selection-container">
            {element.type === 'text' && (element.label === 'Client') && (
              <input className={styles.jobsreadonlyedit} type="text" placeholder={element.label} style={{ textAlign: 'left' }} 
                required={element.isRequired} value={jobInfo && jobInfo[0] ? jobInfo[0]["client_name"] : null} />
            )}
            {element.type === 'text' && (element.label === 'Inspector') && (
              <input className={styles.jobsreadonlyedit} type="text" placeholder={element.label} style={{ textAlign: 'left' }} 
                required={element.isRequired} value={user_name} />
            )}
            {element.type === 'text' && (element.label === 'Start Time') && (
              <input className={styles.jobsreadonlyedit} type="text" placeholder={element.label} style={{ textAlign: 'left' }} 
                required={element.isRequired} value={jobInfo && jobInfo[0] ? jobInfo[0]["start_time"] : null} />
            )}
            {element.type === 'text' && (!element.isHeaderOnly && element.label !== 'Client') && (element.label !== 'Start Time') && (element.label !== 'Inspector') && (
              <input className={styles.jobs_check_value} type="text" placeholder={element.label} style={{ textAlign: 'left' }} 
                required={element.isRequired} disabled={readOnly} 
                onChange={(e) => handleInputChange(element, e.target.value)} value={formValues[element.label] !== undefined ? formValues[element.label] : element.value || ''} />
            )}
            {element.type === 'text' && (element.isExtraValueType) && (
              <>
               <select className={styles.jobs_check} disabled={true} defaultValue={element.isExtraValueType}>
                <option value="kPa">kPa</option>
                <option value="A">A</option>
                <option value="V">V</option>
                <option value="L/min">L/min</option>
                <option value="kW">kW</option>
                <option value="rmp">rmp</option>
                <option value="ml">ml</option>
                <option value="sec">sec</option>
                <option value="rpm">rpm</option>
                <option value="°C">°C</option>
                <option value="L/s">L/s</option>
                <option value="Hrs">Hrs</option>
                <option value="F">F</option>
              </select>
              </>
            )}
            </div>
          {element.type === 'number' && (
            <input className="jobsreadonlyedit" type="number" placeholder={element.label} 
              required={element.isRequired} disabled={readOnly} onChange={(e) => handleInputChange(element, e.target.value)} value={element.value} />
          )}
          {element.type === 'email' && (
            <input className="jobsreadonlyedit" type="email" placeholder={element.label} 
              required={element.isRequired} disabled={readOnly} onChange={(e) => handleInputChange(element, e.target.value)} value={element.value} />
          )}
          {element.type === 'date' && (
            <input className="jobsreadonlyedit" type="date" placeholder={element.label} 
              required={element.isRequired} disabled={readOnly} onChange={(e) => handleInputChange(element, e.target.value)} value={element.value} />
          )}
          {element.type === 'checkbox' && (
            <input className="jobsreadonlyedit" type="checkbox" placeholder={element.label} 
              required={element.isRequired} disabled={readOnly} onChange={(e) => handleInputChange(element, e.target.value)} value={element.value} />
          )}
          {element.type === 'file' && <input type="file" placeholder={element.label} 
            required={element.isRequired} onChange={(e) => handleFileChange(element, e.target.value)}/>}
          {element.type === 'textarea' && (
            <textarea className={styles.jobs_notes} placeholder={element.label} 
              required={element.isRequired} rows={4} disabled={readOnly}
              value={formValues[element.label] !== undefined ? formValues[element.label] : element.value || ''} onChange={(e) => handleInputChange(element, e.target.value)} />
          )}

          {loading && (
            <div className={styles.loaderContainer}>
              <div className={styles.loader}></div>
              <span>Loading...</span>
            </div>
          )}
          {!readOnly ?
            element.type === 'button' && element.label === 'Next' && (
              <input type="submit" onClick={handleNext} className="submitBtn" value={element.label} required={element.isRequired} />
            )
          :
            element.type === 'button' && element.label === 'Next' && (
              <input type="submit" onClick={GoToInspectSignature} className="submitBtn" value={element.label} required={element.isRequired} />
          )}
          {readOnly ?
            element.type === 'button' && element.label === 'Re-edit Details' && (
              <input type="submit" onClick={() => setReadOnly(false)} className="submitBtn" value={element.label} required={element.isRequired} />
            )
          :
            null
          }
          {!readOnly ?
            element.type === 'button' && element.label === 'Back To Clients' && (
              <input type="submit" onClick={handleBack} className="submitBtn" value={element.label} required={element.isRequired} />
          )
          :
            null
          }
          
          {element.isSelection === 'all' && (
            <select className={styles.jobs_check} disabled={readOnly} onChange={(e) => handleInputChange(element, e.target.value)} value={formValues[element.label] !== undefined ? formValues[element.label] : element.value || ''}>
              <option value=""></option>
              <option value="yes">Yes</option>
              <option value="no">No</option>
              <option value="na">N/A</option>
            </select>
          )}
          {element.isSelection === 'no' && (
            <select className={styles.jobs_check} disabled={readOnly} onChange={(e) => handleInputChange(element, e.target.value)} value={formValues[element.label] !== undefined ? formValues[element.label] : element.value || ''}>
              <option value=""></option>
              <option value="no">No</option>
            </select>
          )}
          {element.isSelection === 'yes' && (
            <select className={styles.jobs_check} disabled={readOnly} onChange={(e) => handleInputChange(element, e.target.value)} value={formValues[element.label] !== undefined ? formValues[element.label] : element.value || ''}>
              <option value=""></option>
              <option value="yes">Yes</option>
            </select>
          )}
          {element.isSelection === 'yesno' && (
            <select className={styles.jobs_check} disabled={readOnly} onChange={(e) => handleInputChange(element, e.target.value)} value={formValues[element.label] !== undefined ? formValues[element.label] : element.value || ''}>
              <option value=""></option>
              <option value="yes">Yes</option>
              <option value="no">No</option>
            </select>
          )}
          {element.isSelection === 'F' && (
            <select className={styles.jobs_check} disabled={readOnly} onChange={(e) => handleInputChange(element, e.target.value)} value={formValues[element.label] !== undefined ? formValues[element.label] : element.value || ''}>
              <option value=""></option>
              <option value="F">F</option>
              <option value="¾">¾</option>
              <option value="½">½</option>
              <option value="¼">¼</option>
              <option value="E">E</option>
            </select>
          )}
          </div>
        ))}
      </form>
    </div>
    </>
  )
};

export default InspectionView;
import React, { useEffect, useState } from 'react';
import styles from './Clients.module.scss';
import { post } from '../../api';
import { toast } from 'react-toastify';
import { useHistory } from "react-router-dom";

const ClientNew = () => {
    const [client_name, setClientName] = useState('');
    const [contact_person, setContactPerson] = useState('');
    const [contact_number, setContactNumber] = useState('');
    const [contact_email, setContactEmail] = useState('');
    const history = useHistory();

    const submit = async (event) => {
        event.preventDefault();
        const res = await post('/client_save', { client_name, contact_person, contact_number, contact_email });
        if (res.ok)
        {
            toast.success("New client has been saved.", res.statusText);
            history.push('/clients');
        }
        else if (res.status === 404)
        {
            toast.error("Client already exist.");
        }
        else
        {
            toast.error("Something went wrong with saving the settings. Please contact system administrator.");
        }
        return res.ok;
    };
    return (
        <div>
            <form method="post" onSubmit={submit}>
                <div className={styles.main}>
                    <h1 className={styles.myheading}>New Client</h1>
                    <label className={styles.my_label}>Client Name:</label><br/>
                    <input className={styles.my_edit} type="text" value={client_name} onChange={(e) => setClientName(e.target.value)}/><br/>
                    <label className={styles.my_label}>Contact Person:</label><br/>
                    <input className={styles.my_edit} type="text" value={contact_person} onChange={(e) => setContactPerson(e.target.value)}/><br/>
                    <label className={styles.my_label}>Contact Number:</label><br/>
                    <input className={styles.my_edit} type="text" value={contact_number} onChange={(e) => setContactNumber(e.target.value)}/><br/>
                    <label className={styles.my_label}>Contact Email Address:</label><br/>
                    <input className={styles.my_edit} type="text" value={contact_email} onChange={(e) => setContactEmail(e.target.value)}/><br/>
                </div>
                <div className={styles.main}>
                    <input type="submit" className={styles.btnOk} value="Save"/>
                </div>
            </form>
        </div>
    )
};

export default ClientNew;
import React, { useState, useEffect } from 'react';

import styles from './Forms.module.scss';
import { get, post } from '../../api';
import Inspection from '../inspection/Inspection';
import { useHistory } from "react-router-dom";
import { toast } from 'react-toastify';
import  secureLocalStorage  from  "react-secure-storage";
import Modal from '../utils/Modal';
import { setLocalStorageItem, getLocalStorageItem } from '../utils/Utils';
import { getFormsInfo, getJobsWhereInfo, updateJobs, getJobsWhereInfoStatus1, saveClientInfo, syncFormsInfo, saveJobForm, saveSiteVisit, getSiteVisits, getCurrentJob } from '../../dexieDB';

const Forms = () => {
  const [forms, setForms] = useState([]);
  const [activeForm, setActiveForm] = useState(false);
  const history = useHistory();
  const clientid = history.location.state?.client_id;
  const [jobResp, setJobResp] = useState({});
  const userid = JSON.parse(secureLocalStorage.getItem('user')).id;
  const user_info = JSON.parse(secureLocalStorage.getItem('user'));
  const [currentTime, setCurrentTime] = useState(new Date());
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalRestoreOpen, setIsModalRestoreOpen] = useState(false);
  const [isModalFinishOpen, setIsModalFinishOpen] = useState(false);
  const [jobs, setJobs] = useState([]);
  const [jobActive, setJobActive] = useState(false);
  const [currentJob, setCurrentJob] = useState([]);
  const [siteVisit, setSiteVisit] = useState(false);
  const [jobid, setJobId] = useState(0);
  const [activeJob, setActiveJob] = useState(0);
  const isOnline = navigator.onLine;

  const formattedTime = currentTime.toLocaleTimeString();
  const groupedForms = {};

  const closeModal = () => setJobActive(false);

  const checkStatus = async (clientid, userid, status) => {
    if (!Boolean(currentJob[0]?.inspect_signature)) {
      toast.error("No signature has been created on your profile");
    }
    else {
    setSiteVisit(true);
    if (isOnline) {
        try {
            setLocalStorageItem('active_site_visit', 1);
            const res = await post('/site_visit_save', { clientid, userid, status });
            const resJobs = await post('/jobs_save', { formName: '', clientid, userid });
            const resVisit = await post('/get_site_visit', { clientid, userid, status });
            const formsResp = await get('/forms_list');
            if (res.ok)
            {
            const jobResps = await resJobs.json();
            setJobResp(jobResps);
            let client = await resVisit.json(); 
            await saveClientInfo(client);
            let forms = await formsResp.json(); 
            syncFormsInfo(forms);
            let job = await saveJobForm('', clientid, userid);
            setJobResp({status: "success", job_id: job});
            saveSiteVisit(clientid, userid, status, job);
            toast.success("Site visit has been saved successfully. You can now sync for offline use", res.statusText);
            return true
            }
            else
            {
            toast.error("Something went wrong with saving the site visit. Please try again or contact system administrator.");
            }
        }
        catch(e) {
            toast.error("unexpected error occured for site visit", e);
        }
    } else {
        setSiteVisit(false);
        toast.error("You must be online before you can start a site visit");
    }
    }
  };

  const finishClient = async() => {
    setIsModalFinishOpen(true);
    setSiteVisit(true);
  };

  const onFormClick = async (formName) => {
    setActiveForm(formName);

    if (isOnline) {
      try {
        const res = await post('/jobs_updated', { formName, jobid: jobResp["job_id"] });

          if (res.ok) {
            return true;
          } else {
              toast.error("Unable to start job. Please try again or contact the system administrator.");
          }

          return res.ok;
      } catch (error) {
          console.error("Error saving job online:", error);
          toast.error("An error occurred while starting job. Please try again or contact the system administrator.");
          return false;
      }
    } else {
        try {
          let siteVisits = await getSiteVisits();
          if (siteVisits) {
            let currentJob = await getCurrentJob(siteVisits["jobid"]);
            setActiveJob(currentJob[0]?.jobid);
            let job = await updateJobs(jobResp["job_id"] ? jobResp["job_id"] : currentJob[0]?.jobid, formName);
            setJobResp({status: "success", job_id: job});
            return true;
          } else {
            let job = await saveJobForm(formName, clientid, userid);
            setJobResp({status: "success", job_id: job});
            saveSiteVisit(clientid, userid, 1, job);
            setActiveJob(job);
          }
        } catch (error) {
            console.error("Error saving job offline:", error);
            toast.error("An error occurred while saving job locally. Please try again later.");
            return false;
        }
    }
  };
  
  const deleteClient = async (e) => {
    e.preventDefault();
    const res = await post('/client_delete', { clientid });
    
    if (res.ok)
    {
    toast.success("Client has been deleted.", res.statusText);
    history.push('/clients');
    }
    else
    {
    toast.error("Something went wrong with deleting the client. Please try again or contact system administrator.");
    }
    return res.ok;
  } 

  const restoreClient = async (e) => {
    e.preventDefault();
    const res = await post('/client_restore', { clientid });
    
    if (res.ok)
    {
    toast.success("Client has been restored.", res.statusText);
    history.push('/clients');
    }
    else
    {
    toast.error("Something went wrong with restoring the client. Please try again or contact system administrator.");
    }
    return res.ok;
  } 
  
  useEffect(() => {
    const fetchData = async () => {
        if (isOnline) {
            try {
                const resp = await get(`/forms_list`);
                if (resp.ok) {
                    const formsData = await resp.json();
                    setForms(formsData);
                } else {
                    toast.error("Failed to fetch forms: " + resp.statusText);
                }
            } catch (error) {
                toast.error("Error fetching forms: " + error.message);
            }
        } else {
            try {
                const formsData = await getFormsInfo();
                console.log("formsData", formsData);
                setForms(formsData);
            } catch (error) {
                toast.error("Error fetching forms from Offline DB: " + error.message);
            }
        }
    };

    fetchData();
  }, [isOnline]);


  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentTime(new Date());
    }, 10000);
    return () => clearInterval(intervalId);
  }, []); 

  forms.forEach((form) => {
    if (navigator.onLine) {
    const category = JSON.parse(form.form_data).inspectionCategory;
    if (!groupedForms[category]) {
      groupedForms[category] = [];
    }

    groupedForms[category].push(form);
  }
  else {
    if (!groupedForms.offline) {
      groupedForms.offline = [];
    }
    groupedForms.offline.push(form);
  }
  });

  useEffect(() => {
    currentJob.map((job) => {
      if (job.current_job > 0) {
        setJobActive(true);
        setJobId(job.current_job);
        const userString = secureLocalStorage.getItem('user');
        const user = JSON.parse(userString);
        user.current_job = job.current_job;
        secureLocalStorage.setItem('user', JSON.stringify(user));
      }
      else {
        setJobActive(false);
      }
      return null;
    })
  }, [currentJob]);

  useEffect(() => {
    const fetchData = async () => {
        try {
            if (isOnline) {
                console.log("Fetching jobs online...");
                const resp = await post(`/jobs_list`, { userid, filterlist: `WHERE status = 3` });
                if (resp.ok) {
                    const data = await resp.json();
                    setJobs(data);
                } else {
                    toast.error("Unable to get jobs list");
                }
            } else {
                setSiteVisit(true);
                console.log("Fetching jobs offline...");
                const jobsData = await getJobsWhereInfo(userid);
                console.log("jobsData", jobsData);
                setJobs(jobsData);
            }
        } catch (error) {
            console.error("Error fetching jobs:", error);
            toast.error("An error occurred while fetching jobs");
        }
    };

    fetchData();
  }, [isOnline, userid]);

  
  useEffect(() => {
    const fetchData = async () => {
        try {
            if (isOnline) {
                if (userid !== '' && userid !== undefined) {
                    console.log("Fetching current job online...");
                    const resp = await post(`/user`, { userid, filterlist: `WHERE id = ${userid} AND status = 1` });
                    if (resp.ok) {
                        const data = await resp.json();
                        setCurrentJob(data);
                    } else {
                        toast.error("Unable to get the current job");
                    }
                }
            } else {
                console.log("Fetching current job offline...");
                const jobsData = await getJobsWhereInfoStatus1(userid);
                console.log("jobsData", jobsData);
                setCurrentJob(jobsData);
            }
        } catch (error) {
            console.error("Error fetching current job:", error);
            toast.error("An error occurred while fetching the current job");
        }
    };

    fetchData();
  }, [isOnline, userid]);
  
  return (
    <>
    {!jobid > 0 ? 
    <div className={styles.main}>
      <div className={styles.form}>
      <span className={styles.clientHead}>Client</span>
      <br />
      <input className={styles.clientInput} type="text" 
        value={history.location.state ? history.location.state.client_name : null} 
        readOnly={true} 
      />
      <br />
      <button className={styles.clientBtn}>Edit Client</button>
      {user_info.is_sysadmin === 1 ?
        <>
        <button className={styles.clientBtn} onClick={() => setIsModalOpen(true)}>Delete Client</button>
        <button className={styles.clientBtn} onClick={() => setIsModalRestoreOpen(true)}>Restore Client</button>
        <button className={styles.clientBtn} onClick={() => finishClient(true)}>Finish Client</button>
        </>
        : null
      }
      {isModalOpen ?
        <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} onContinue={deleteClient} content={"Are you sure you want to delete this client?"}/>
        : null
      }
      {isModalRestoreOpen ?
        <Modal isOpen={isModalRestoreOpen} onClose={() => setIsModalRestoreOpen(false)} onContinue={restoreClient} content={"Are you sure you want to restore this client?"}/>
        : null
      }
      
      <br />
      <br />
      <br />
      {!siteVisit && Number(getLocalStorageItem('active_site_visit')) !== 1 ?
        <button className={styles.clientBtn} onClick={() => checkStatus(clientid, userid, 1)}>Start Site-Visit</button>
      : null
      }
      {isModalFinishOpen ? (
        Object.entries(groupedForms).map(([category, categoryForms]) => {
            const hasVisibleButtons = categoryForms.length > 0;

            return (
                hasVisibleButtons && siteVisit && (
                    <div key={category} className={styles.categoryGrouping}>
                        <h2 className={styles.categoryHeader}>{category}</h2>
                        {categoryForms.map((form) => (
                            <button
                                key={form.form_name}
                                className={styles.formsBtn}
                                onClick={() => onFormClick(form.form_name)}
                            >
                                {JSON.parse(form.form_data).displayName}
                            </button>
                        ))}
                    </div>
                )
            );
        })
    ) : (
        Object.entries(groupedForms).map(([category, categoryForms]) => {
          const visibleButtons = categoryForms.filter(form => {
              return !jobs.some(job => job.template_name === form.form_name && job.clientid === clientid);
          });
          const hasVisibleButtons = visibleButtons.length > 0;

           return (
          hasVisibleButtons && siteVisit && (
            !activeForm ? (
              <div key={category} className={styles.categoryGrouping}>
                <h2 className={styles.categoryHeader}>{category}</h2>
                {visibleButtons.map((form) => (
                  <button
                    key={form.form_name}
                    className={styles.formsBtn}
                    onClick={() => onFormClick(form.form_name)}
                  >
                    {JSON.parse(form.form_data).displayName}
                  </button>
                ))}
              </div>
            ) : (
              categoryForms.map((form) => (
                activeForm === form.form_name && (
                    jobResp["status"] === "success" ? (
                      <Inspection
                        key={form.form_name}
                        formData={JSON.parse(form.form_data)}
                        templateName={activeForm}
                        jobId={jobResp["job_id"] ? jobResp["job_id"] : activeJob}
                        clientName={history.location.state.client_name}
                        dateTime={formattedTime}
                      />
                  ) : null
                )
              ))
            )
          )
      );
        })
      )
    }
      </div>
    </div>
    : <Modal isOpen={jobActive} content={"There is already a job started. Cannot start another job."} onClose={closeModal}/>}
    </>
  )
};

export default Forms;